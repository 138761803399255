import { useEffect,useState,useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUnreadTot } from 'store/chat/chat';

function UnreadTotSpan(props){
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const chat = useSelector((state) => state.chat);

  useEffect(() => {
    const timerId=setInterval(getUnreadCount,5000);
    return () => clearInterval(timerId);
  },[]);
  

  const getUnreadCount=()=>{
    if(user.user_seq==""){
      return false;
    }
    let unread_list_opt={
      "s_user_seq":user.user_seq,
      "is_need_count":"1",
      "is_need_info_arr":"",
      "is_no_limit":"1",
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/chat/unread/unread/list',unread_list_opt)
    .then((response) => {
      if(response.data["result"]==="true"){
        dispatch(setUnreadTot({
          "tot":response.data["data"]["tot"]
        }));
      }
    });
  };

  return (
    <span className="text-red-500" >{chat.unreadToT!="0"?"("+chat.unreadToT+")":""}</span>
  );
}

export default UnreadTotSpan;
