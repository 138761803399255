import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from 'store/user/user';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import xColumnArr from 'components/home/xcolumn/basic/sort/list';

function WritePopupContent(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const [rowData,setRowData]=useState({
    "a_par_id":"",
    "a_par_col":"",
    "a_sort_name":"",
    "a_type":"fix",
  });

  useEffect(()=>{
    if(props.isUpdate){
      let changeRowData={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const goWrite=useCallback( e => {
    if(rowData.a_par_id==""){
      alert("부모구분 입력이 필요합니다.");
      return false;
    }
    if(rowData.a_par_col==""){
      alert("부모컬럼 입력이 필요합니다.");
      return false;
    }
    if(rowData.a_sort_name==""){
      alert("구분명 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }


    let formJsonData={
      "data_arr":[rowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(props.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/sort/sort/write',formJsonData)
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.sort_data_create_date="";
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="130px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>부모구분</th>
              <td>
                <select className="row-input" name="a_par_id" value={rowData.a_par_id} onChange={handleInputChange}>
                  {xColumnArr["select_arr"]["a_par_id"].map((item,idx) => (
                    <option value={item.value} key={idx}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>부모컬럼</th>
              <td>
                <input type="text" className="row-input" name="a_par_col" value={rowData.a_par_col} onChange={handleInputChange} placeholder="부모컬럼" />
              </td>
            </tr>
            <tr>
              <th>구분명</th>
              <td>
                <input type="text" className="row-input" name="a_sort_name" value={rowData.a_sort_name} onChange={handleInputChange} placeholder="구분명" />
              </td>
            </tr>
            <tr>
              <th>구분</th>
              <td>
                <select className="row-input" name="a_type" value={rowData.a_type} onChange={handleInputChange}>
                  {xColumnArr["select_arr"]["a_type"].map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
