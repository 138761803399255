import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from 'lib/lyg/date_func';
import {customHeaderFunc} from 'components/common/date_picker/custom_header';
import {CustomInputWrite} from 'components/common/date_picker/custom_input';

function WritePopupContent(props) {
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const [rowData,setRowData]=useState({
    "a_seq":"",
    "a_corp_seq":"",
    "a_project_name":"",
    "a_content":"",
    "a_start_date":"",
    "a_end_date":"",
  });

  useEffect(()=>{
    if(props.isUpdate){
      let changeRowData={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };
  const onChangeDatePicker=(key,date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
  };

  const goWrite=useCallback( e => {
    if(rowData.a_project_name==""){
      alert("프로젝트명 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let formJsonData={
      "data_arr":[rowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(props.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
      formJsonData["pri_data_arr"]=[props.rowData];
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/project/project/project/write',formJsonData)
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.project_data_create_date="";
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);
  
  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>프로젝트</th>
              <td>
              <input type="text" className="row-input" name="a_project_name" value={rowData.a_project_name} onChange={handleInputChange} placeholder="프로젝트" />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p className="con-p-gray">설명</p>
                <textarea className="row-input h-44" name="a_content" value={rowData.a_content} onChange={handleInputChange} placeholder="설명입력" ></textarea>
              </td>
            </tr>
            <tr>
              <th>시작</th>
              <td>
                <DatePicker 
                  selected={rowData.a_start_date!=""?new Date(rowData.a_start_date):null} 
                  onChange={(date) => {
                    onChangeDatePicker("a_start_date",date);
                  }}
                  locale={ko} 
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
            <tr>
              <th>종료</th>
              <td>
                <DatePicker 
                  selected={rowData.a_end_date!=""?new Date(rowData.a_end_date):null} 
                  onChange={(date) => {
                    onChangeDatePicker("a_end_date",date);
                    }
                  } 
                  locale={ko} 
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
