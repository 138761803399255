import { useNavigate } from 'react-router-dom';
import DarkModeBtn from 'components/common/dark_mode/dark_mode';

function MenuOne(props){
  let menu_class="btn-l";
  if(props.is_active){
    menu_class+=" btn-brown";
  }else{
    menu_class+=" btn-line-gray";
  }
  return (
    <button className={menu_class} to={props.url} key={props.index} onClick={props.movePage}>
      {props.text}
    </button>
  );
}

function TestTopMenu() {
  const navigate = useNavigate();
  let menu=[
    {"url":"/test/main","text":"Home","is_active":false},
    {"url":"/test/design","text":"디자인","is_active":false},
    {"url":"/test/ex","text":"외부","is_active":false},
  ];
  for(let i=0;i<menu.length;i++){
    if(menu[i].url===window.location.pathname.substring(0,menu[i].url.length)){
      menu[i].is_active=true;
      document.title = menu[i].text;
    }
  }

  const movePage=(e)=>{
    navigate(e.target.getAttribute("to"));
  };

  const menus = menu.map((rowData, index) =>(
      <MenuOne is_active={rowData.is_active} url={rowData.url} key={index} movePage={movePage} text={rowData.text}>
        {rowData.text}
      </MenuOne>
    )
  );

  return (
    <div className="flex fle-row gap-1 justify-center shadow-md border-gray-300">
      {menus}
      <DarkModeBtn></DarkModeBtn>
    </div>
  );
}

export default TestTopMenu;
