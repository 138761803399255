import HoemHeader from 'components/home/header/header';
import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from 'store/user/user';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Link,useNavigate } from 'react-router-dom';

function MyPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const pageData={
    "write_btn_text":"수정",
    "confirm_msg":"수정 하시겠습니까?",
  };
  const [rowData,setRowData]=useState({
    "a_seq":user.user_seq,
    "a_user_name":"",
    "a_user_tel":"",
    "a_user_phone":"",
    "a_gender":"",
  });

  useEffect(()=>{
    if(user.user_seq==""){
      navigate("/login");
    }else{
      getUserOne();
    }
  },[]);

  const handleInputChange=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const getUserOne=useCallback( e => {
    let formJsonData={
      "s_seq":user.user_seq
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/user/list',formJsonData)
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length ==1){
          let info=response.data["data"]["info_arr"][0];
          setRowData({
            "a_seq":info["a_seq"],
            "a_user_name":info["a_user_name"],
            "a_user_tel":info["a_user_tel"],
            "a_user_phone":info["a_user_phone"],
            "a_gender":info["a_gender"],
          });
        }else{
          navigate("/login");
        }
      }else{
        navigate("/login");
      }
    });

  }, [rowData]);

  const goWrite=useCallback( e => {
    if(rowData.a_user_name==""){
      alert("이름 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let formJsonData={
      "data_arr":[rowData],
      "is_update":"1",
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/user/write',formJsonData)
    .then((response) => {
      if(response.data["result"]==="true"){
        dispatch(setUser({
          user_id:user.user_id,
          user_seq:user.user_seq,
          user_name:rowData["a_user_name"],
        }));
        refreshToken();
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  const refreshToken=() => {
    let formJsonData={
      "token_id":localStorage.lyg_mproject_token_id
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/login/refresh_token',formJsonData)
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.lyg_mproject_token_id=response.data["data"]["token_id"];
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <div>
      <HoemHeader></HoemHeader>
      <div className="main-content-wrap">
        <div className="main-content-div">
          <p className="con-p-gray">마이페이지</p>
          <div className="write-table-div mt-1">
            <table>
              <colgroup>
                <col width="120px"></col>
                <col width="*"></col>
              </colgroup>
              <tbody>
                <tr>
                  <th>아이디</th>
                  <td>
                    {user.user_id}
                  </td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td>
                    <input type="password" className="row-input"  name="a_user_pw" onChange={handleInputChange} />
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td>
                    <input type="text" className="row-input" name="a_user_name" value={rowData.a_user_name} onChange={handleInputChange} placeholder="이름입력" />
                  </td>
                </tr>
                <tr>
                  <th>핸드폰</th>
                  <td>
                    <input type="text" className="row-input" name="a_user_phone" value={rowData.a_user_phone} onChange={handleInputChange} placeholder="핸드폰" />
                  </td>
                </tr>
                <tr>
                  <th>전화</th>
                  <td>
                    <input type="text" className="row-input" name="a_user_tel" value={rowData.a_user_tel} onChange={handleInputChange} placeholder="전화" />
                  </td>
                </tr>
                <tr>
                  <th>성별</th>
                  <td>
                    <select className="row-input" name="a_gender" value={rowData.a_gender} onChange={handleInputChange}>
                      <option value="">선택없음</option>
                      <option value="m">남</option>
                      <option value="f">여</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btn-box-center mt-2">
            <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPage;
