import HoemHeader from 'components/home/header/header';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import LayerPopup from 'components/common/popup/layer/layer_popup';
import ViewPopupContent from 'pages/home/basic/user/popup/view/index';
import { useSelector, useDispatch } from 'react-redux';
import Paging from 'components/common/list/paging/paging';

function UserAuthList() {
  const dark = useSelector((state) => state.dark);
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [pageData, setPageData] = useState({
    listOpt:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_start_date':'',
      's_end_date':''
    },
    aggridSize:{
      height:(window.innerHeight/5)*3,
      width:window.innerWidth
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [columnDefs, setColumnDefs] = useState([
    {headerName: '이름',field: 'a_user_name', width:100},
    {headerName: '아이디',field: 'a_user_id'},
    {headerName: '등급',field: 'a_user_grade'},
  ]);
  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });
  const defaultColDef = useMemo( ()=> ({
    initialWidth: 100,
    sortable: true,
    resizable: true,
    filter: true,
  }));
  const cellClickedListener = useCallback( event => {
    //console.log('cellClicked', event);
  }, []);

  let aggridWrapClassName = "ag-theme-alpine w-full mt-1";
  if (dark.dark === 'dark' ) {
    aggridWrapClassName="ag-theme-alpine-dark w-full mt-1";
  }
  useEffect(() => {
    list();
  }, []);

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/user/list',pageData.listOpt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
      }else{
        
      }
    });
  };

  const openOnePopup=(isUpdate,sort)=>{
    if(isUpdate==undefined){isUpdate=false;}
    if(sort==undefined){sort="write";}
    let tmp_rowData={};
    let pop_title="등록";
    if(isUpdate){
      pop_title="수정";
      let selected_row=gridRef.current.api.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "title":pop_title,
      "sort":sort
    });
  };

  return (
    <div>
      <HoemHeader></HoemHeader>
      <div className="main-content-wrap">
        <div className="main-content-div">
          <h1 className="con-p-gray">유저권한 ({countInfo["tot"]} 건) </h1>
          <div className="btn-box-left">
            <button className="btn btn-blue" onClick={()=>{openOnePopup(true,"view");}} >보기</button>
          </div>
          <div className={aggridWrapClassName} style={{height: pageData.aggridSize.height}}>
            <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API

                rowData={infoArr} // Row Data for Rows

                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties

                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection='multiple' // Options - allows click selection of rows

                onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                />
          </div>
          <div>
            <Paging now_page={pageData.listOpt.now_page}
              num_per_page={pageData.listOpt.num_per_page}
              total_rec={countInfo.tot} 
              onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
          </div>
        </div>
      </div>
      {popupData.isOpen && 
        <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
          width={popupData.width} height={popupData.height} >
          {popupData.sort==="view"&&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></ViewPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
}

export default UserAuthList;
