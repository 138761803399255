import {hmenu,smenu} from "components/home/menu/data/menu_arr";
import sortxColumnArr from 'components/home/xcolumn/basic/sort/list';

const getAuthSortData=()=>{
  let authSortData={
    a_type:[
      {"value":"table","text":"테이블"},
      {"value":"hmenu","text":"대메뉴"},
      {"value":"smenu","text":"소메뉴"}
    ],//{"value":"","text":"선택없음"}
    a_user_grade:[],
    a_crud:[],
    table_ids:[],
    hmenu_ids:[],
    smenu_ids:[],
  };
  
  if(localStorage.sort_data_arr){
    let sort_data_arr=JSON.parse(localStorage.sort_data_arr);
    if(sort_data_arr["authg"]){
      if(sort_data_arr["authg"]["a_user_grade"]){
        for(let i=0;i<sort_data_arr["authg"]["a_user_grade"].length;i++){
          if(sort_data_arr["authg"]["a_user_grade"][i]["value"]!=""
            &&sort_data_arr["authg"]["a_user_grade"][i]["value"]!="master"){
            authSortData["a_user_grade"].push(sort_data_arr["authg"]["a_user_grade"][i]);
          }
        }
      }
      if(sort_data_arr["authg"]["a_crud"]){
        for(let i=0;i<sort_data_arr["authg"]["a_crud"].length;i++){
          if(sort_data_arr["authg"]["a_crud"][i]["value"]!=""){
            authSortData["a_crud"].push(sort_data_arr["authg"]["a_crud"][i]);
          }
        }
      }
      for(let i=0;i<sortxColumnArr.select_arr["a_par_id"].length;i++){
        if(sortxColumnArr.select_arr["a_par_id"][i]["value"]!=""){
          authSortData["table_ids"].push(sortxColumnArr.select_arr["a_par_id"][i]);
        }
      }
      if(sort_data_arr["authg"]["table_ids"]){
        authSortData["table_ids"]=sort_data_arr["authg"]["table_ids"];
      }
    }
  }
  for(let key in hmenu){
    authSortData["hmenu_ids"].push({"value":key,"text":hmenu[key]["name"]});
  }
  for(let key in smenu){
    if(smenu[key]["is_show"]=="1"){
      authSortData["smenu_ids"].push({"value":key,"text":smenu[key]["name"]});
    }
  }

  return authSortData;
};
export default getAuthSortData;