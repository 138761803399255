import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import LayerPopup from 'components/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view/index';
import { useSelector, useDispatch } from 'react-redux';
import Paging from 'components/common/list/paging/paging';
import ListAggrid from 'components/common/list/aggrid/list';
import xColumnArr from 'pages/home/basic/sortd/xcolumn/list';

const SortDRight=forwardRef((props,ref)=> {
  const listAggridRef= useRef();
  const [pageData, setPageData] = useState({
    listOpt:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_order_num',
      'order_type':'',
      's_start_date':'',
      's_end_date':'',
      's_par_id':"empty",
      's_par_col':"empty",
      'sc':{},
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    sortInfo:null,
    title:"팝업",
    width:"800px",
    height:"80%",
  });
  const defaultColDef = useMemo( ()=> (
    {
      floatingFilter:true
    }
  ));
  const cellClickedListener = useCallback( event => {
    //console.log('cellClicked', event);
  }, []);
  
  useEffect(() => {
    list();
  }, []);

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/sortd/sortd/list',pageData.listOpt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        let tmp_info_arr=response.data["data"]["info_arr"];
        let start_index=parseInt(response.data["data"]["start_index"]);
        for(let i=0;i<tmp_info_arr.length;i++){
          tmp_info_arr[i].idx_num=start_index;
          start_index--;
        }
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list
  }))

  const goDelete = useCallback( e => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    let tmp_delete_rows=[];
    for(let i=0; i<selected_row.length; i++){
      tmp_delete_rows.push({
        "a_par_id":selected_row[i]["a_par_id"],
        "a_par_col":selected_row[i]["a_par_col"],
        "a_seq":selected_row[i]["a_seq"],
      });
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/sortd/sortd/delete',{
      data_arr:tmp_delete_rows,
    },).then((response) => {
      if(response.data["result"]==="true"){
        localStorage.sort_data_create_date="";
        list();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const goWriteArr=useCallback( e => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("선택 수정 하시겠습니까?")){
      return false;
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/sortd/sortd/write',{
      data_arr:selected_row,
      //is_update:"1",
    },).then((response) => {
      if(response.data["result"]==="true"){
        localStorage.sort_data_create_date="";
        list();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const goAddRowData=(e)=>{
    if(pageData.listOpt.s_par_id!="empty"){
      let allRows=listAggridRef.current.getRows();
      listAggridRef.current.addRowData({
        data:[{
          "a_par_id":pageData.listOpt.s_par_id,
          "a_par_col":pageData.listOpt.s_par_col,
          "a_seq":"",
          "a_value":"",
          "a_text":"",
          "a_order_num":allRows.length+1,
        }]
      });
    }
  };

  const setOrderNumAuto=(e)=>{
    let allRows=listAggridRef.current.getRows();
    for(let i=0;i<allRows.length;i++){
      allRows[i]["a_order_num"]=i+1;
    }
    listAggridRef.current.setInfoArr(allRows);
  };

  const openOnePopup=(isUpdate,sort)=>{
    if(isUpdate==undefined){isUpdate=false;}
    if(sort==undefined){sort="write";}
    let tmp_rowData={};
    let pop_title="등록";
    if(isUpdate){
      pop_title="수정";
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "sortInfo":{...props.rowData},
      "title":pop_title,
      "sort":sort
    });
  };

  return (
    <div>
      <h1 className="con-p-gray">
        <span className="text-green-500 dark:text-green-200 mr-2 font-bold">{props.rowData?props.rowData.a_sort_name:""}</span>
        구분
         ({countInfo["tot"]} 건) 
      </h1>
      <div className="btn-box-left">
        {/* <button className="btn btn-blue" onClick={()=>{openOnePopup(true,"view");}} >보기</button>
        <button className="btn btn-blue" onClick={()=>{openOnePopup(false);}} >등록</button>
        <button className="btn btn-blue" onClick={()=>{openOnePopup(true);}} >수정</button> 
        <button className="btn btn-round-green" onClick={setOrderNumAuto}>순번자동</button> */}
        <button className="btn btn-round-orange" onClick={goDelete}>삭제</button>
        <button className="btn btn-round-green" onClick={goAddRowData}>추가</button>
        <button className="btn btn-round-green" onClick={()=>{listAggridRef.current.toggleGridEditing();}}>수정모드</button>
        <button className="btn btn-round-green" onClick={goWriteArr}>선택저장</button>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={xColumnArr}
        defaultColDef={defaultColDef}
        list={list}
        gridOpt={
          {
            is_grid:true,
            is_add_rowDrag:true,
            onRowDragEnd:setOrderNumAuto
          }
        }
      ></ListAggrid>
      <div>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
      </div>
      {popupData.isOpen && 
        <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
          width={popupData.width} height={popupData.height} >
          {popupData.sort==="write"&&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} sortInfo={popupData.sortInfo} ></WritePopupContent>
          }
          {popupData.sort==="view"&&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></ViewPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
});

export default SortDRight;
