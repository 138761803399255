import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";

const tmp_years =[];
let now_d=new Date();
let start_date=now_d.getFullYear()-50;
let end_date=now_d.getFullYear() + 5;
for(let i=start_date;i<=end_date;i++){
  tmp_years.push(i);
}
export const years=tmp_years;
export const months = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "9",
  "8",
  "10",
  "11",
  "12",
];
export const customHeaderFunc=({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div className="btn-box-center">
    <button className="btn-s btn-dark px-3" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      {"<"}
    </button>
    <select
      className="react-datepicker-header-select"
      value={getYear(date)}
      onChange={({ target: { value } }) => changeYear(value)}
    >
      {years.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <select
      className="react-datepicker-header-select"
      value={months[getMonth(date)]}
      onChange={({ target: { value } }) =>
        changeMonth(months.indexOf(value))
      }
    >
      {months.map((option) => (
        <option key={option} value={option}>
          {option}월
        </option>
      ))}
    </select>

    <button className="btn-s btn-dark px-3" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      {">"}
    </button>
  </div>
);