import { useEffect } from 'react';
import HoemHeader from 'components/home/header/header';

function communityMain() {
  

  return (
    <div>
      <HoemHeader></HoemHeader>
      <div className="main-content-wrap">
        <div className="main-content-div">
          <p className="con-p-gray">커뮤니티</p>
        </div>
      </div>
    </div>
  );
}

export default communityMain;
