import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from 'store/user/user';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import xColumnArr from 'components/home/xcolumn/basic/authg/list';

function WritePopupContent(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const [rowData,setRowData]=useState({
    "a_type":"table",
    "a_id":"",
    "a_user_grade":"user",
    "a_crud":"read",
    "a_is_allow":"",
  });

  useEffect(()=>{
    if(props.isUpdate){
      let changeRowData={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const goWrite=useCallback( e => {
    if(rowData.a_type==""){
      alert("타입 입력이 필요합니다.");
      return false;
    }
    if(rowData.a_id==""){
      alert("구분 입력이 필요합니다.");
      return false;
    }
    if(rowData.a_user_grade==""){
      alert("등급 입력이 필요합니다.");
      return false;
    }
    if(rowData.a_crud==""){
      alert("crud 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let formJsonData={
      "data_arr":[rowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(props.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
      formJsonData["pri_data_arr"]=[props.rowData];
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/authg/authg/write',formJsonData)
    .then((response) => {
      if(response.data["result"]==="true"){
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);
  
  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="130px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>타입</th>
              <td>
                <select className="row-input" name="a_type" value={rowData.a_type} onChange={handleInputChange}>
                  {xColumnArr["select_arr"]["a_type"].map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>구분</th>
              <td>
                <input type="text" className="row-input" name="a_id" value={rowData.a_id} onChange={handleInputChange} placeholder="구분" />
              </td>
            </tr>
            <tr>
              <th>등급</th>
              <td>
                <select className="row-input" name="a_user_grade" value={rowData.a_user_grade} onChange={handleInputChange}>
                  {xColumnArr["select_arr"]["a_user_grade"].map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>CRUD</th>
              <td>
                <select className="row-input" name="a_crud" value={rowData.a_crud} onChange={handleInputChange}>
                  {xColumnArr["select_arr"]["a_crud"].map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>허용여부</th>
              <td>
                <select className="row-input" name="a_is_allow" value={rowData.a_is_allow} onChange={handleInputChange}>
                  {xColumnArr["select_arr"]["a_is_allow"].map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
