import XcolumnFunc from "components/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData={
    x_column_list_arr:[
      {"key":"a_user_seq","name":"회원키","width":"110","is_show":"1"},
      {"key":"a_list_id","name":"리스트","width":"120","is_show":"1"},
      {"key":"a_list_type","name":"타입","width":"100","is_show":"1"},
      {"key":"a_create_date","name":"작성일","width":"180","is_show":"1"},
      {"key":"a_update_date","name":"수정일","width":"180","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      's_xcolumn_obj':'1'
    }
  };
  initData.table="list_opt";
  initData.list_id="list_opt_list";
  const xColumnArr=XcolumnFunc.getDefaultXcolumn(initData);
  
  xColumnArr.pri_col_arr=["a_user_seq","a_list_id","a_list_type"];
  
  xColumnArr.order_id_col_arr.push({"value":"a_user_seq","text":"회원"});
  xColumnArr.order_id_col_arr.push({"value":"a_list_id","text":"리스트"});
  xColumnArr.order_id_col_arr.push({"value":"a_create_date","text":"작성일"});
  xColumnArr.order_id_col_arr.push({"value":"a_update_date","text":"수정일"});
  
  xColumnArr.start_date_type_arr.push({"value":"a_create_date","text":"작성일"});
  xColumnArr.start_date_type_arr.push({"value":"a_update_date","text":"수정일"});
  
  xColumnArr.select_arr=XcolumnFunc.getSortByTable({"table":xColumnArr["table"]});

  return xColumnArr;
};

export default getXcolumnJson;