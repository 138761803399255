import { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';

function JoinPage() {
  const [joinData, setJoinData] = useState({
    "user_id":"",
    "user_pw":"",
    "user_name":"",
  });
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "회원가입";
  }, []);

  const goJoin=()=>{
    if(joinData.user_id==""){
      alert("아이디 입력이 필요합니다.");
      return false;
    }else if(joinData.user_pw==""){
      alert("암호 입력이 필요합니다.");
      return false;
    }else if(joinData.user_name==""){
      alert("이름 입력이 필요합니다.");
      return false;
    }

    if(window.confirm("가입 하시겠습니까?")){
      window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/login/join',joinData,)
      .then((response) => {
        if(response.data["result"]==="true"){
          navigate("/login");
        }else{
          alert(response.data["msg"]);
        }
      });
    }
  };

  const handleInputChange=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setJoinData({
      ...joinData,
      ...{[name]: value}
    });
  };

  return (
    <div>
      <div className="main-content-wrap">
        <div className="main-content-div">
          <h1 className="con-title">회원가입</h1>
          <div className="write-table-div">
            <table className="max-w-3xl" style={{margin:"10px auto"}}>
              <colgroup>
                <col width="150px"></col>
                <col width="*"></col>
              </colgroup>
              <tbody>
                <tr>
                  <th>아이디</th>
                  <td>
                    <input type="text" className="row-input" name="user_id" onChange={handleInputChange} placeholder="아이디" />
                  </td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td>
                    <input type="password" className="row-input" name="user_pw" onChange={handleInputChange}  placeholder="비밀번호" />
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td>
                    <input type="text" className="row-input" name="user_name" onChange={handleInputChange} placeholder="비밀번호" />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="btn-box-center">
              <button className="btn-l btn-blue" onClick={goJoin}>회원가입</button>
              <Link className="btn-l btn-gray" to="/login" >뒤로</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinPage;
