import { Link } from 'react-router-dom';
import TopMenu from 'components/common/test/menu/top_menu';
import { useEffect,useState } from 'react';

function TestMain() {

  return (
    <div>
      <TopMenu></TopMenu>
      <h2 className="text-2xl text-gray-900 dark:text-gray-200" >테스트메인</h2>
      <p><Link className="btn btn-round-green2" to="/">Home</Link></p>
    </div>
  );
}

export default TestMain;
