import XcolumnFunc from "components/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();
import DateFunc from 'lib/lyg/date_func';

const getXcolumnJson=()=>{
  let initData={
    table:"prj_report",
    list_id:"report_list",
    create_user_col:"a_writer_seq",
    x_column_list_arr:[
      {"key":"a_title","name":"제목","width":"130","is_show":"1"},
      {"key":"a_task_date","name":"작업일","width":"130","is_show":"1"},
      {"key":"a_writer","name":"작성자","width":"110","is_show":"1"},
      {"key":"a_create_date","name":"생성일","width":"180","is_show":"1"},
      {"key":"a_update_date","name":"수정일","width":"110","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_task_date DESC,a_create_date DESC',
      'order_type':'',
      's_date_type':'a_task_date',
      's_start_date':"",
      's_end_date':'',
      'sc':{},
      's_writer_seq':'',
    },
    order_id_col_arr:[
      {"value":"a_task_date","text":"작업일"},
      {"value":"a_create_date","text":"작성일"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;