const xColumnArr={
  select_arr:{},
};
xColumnArr.select_arr["a_par_id"]=[
  {"value":"","text":"선택없음"},
  {"value":"corp","text":"법인"},
  {"value":"user","text":"회원"},
  {"value":"token","text":"토큰"},
  {"value":"file","text":"파일"},
  {"value":"reply","text":"리플"},
  {"value":"auth","text":"권한"},
  {"value":"authg","text":"그룹권한"},
  {"value":"sort","text":"구분"},
  {"value":"sortd","text":"구분상세"},
  {"value":"list_opt","text":"리스트설정"},
  {"value":"board","text":"게시판"},
  {"value":"log_login","text":"로그인로그"},

  {"value":"prj","text":"프로젝트"},
  {"value":"prj_conference","text":"회의록"},
  {"value":"prj_plan","text":"설계자료"},
  {"value":"prj_work","text":"작업내역"},
  {"value":"prj_report","text":"업무일지"},

  {"value":"chat_msg","text":"채팅"},
  {"value":"chat_room","text":"채팅방"},
  {"value":"chat_mem","text":"채팅멤버"},
  {"value":"chat_unread","text":"채팅안읽은사람"},
];
xColumnArr.select_arr["a_type"]=[
  {"value":"fix","text":"고정"},
  {"value":"variable","text":"변동"},
];

export default xColumnArr;