import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ListComponent from 'components/common/list/list/list_component';
import Paging from 'components/common/list/paging/paging';
import PopMenu from 'pages/home/chat/x_include/pop_menu/menu';
import getXcolumnJson from '../../xcolumn/list.js';

function ListPage(props) {
  const xColumnArr=getXcolumnJson();
  const user = useSelector((state) => state.user);
  const listRef= useRef();
  const changeRoomNameRef= useRef();
  const gridOpt={
    is_add_idx_num:false,
    is_add_checkbox:true,
    width:"auto",
    table_align:"center",
    td_align:"center",
    rowOnClick:(rowData,idx,e)=>{
      if(e.target.className!="chk_row"&&e.target.className!="btn btn-yellow"&&e.target.className!="btn btn-gray"){
        
      }else if(e.target.className=="btn btn-gray"){
        setChangeNameData({
          ...changeNameData,
          isShow:true,
          roomInfo:rowData,
          roomName:rowData["a_name"],
        });
        setTimeout(()=>{
          changeRoomNameRef.current.focus();
        },100);
      }
    },
  };
  const [pageData, setPageData] = useState({
    listOpt:{
      "s_mem_user_seq":[user.user_seq],
      "s_add_mem_arr":"1",
      "s_add_unread_tot":"1",
      "s_add_unread_user_seq":user.user_seq,
      ...xColumnArr.list_opt
    }
  });
  const [changeNameData, setChangeNameData] = useState({
    isShow:false,
    roomInfo:null,
    roomName:"",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({"tot":0});
  useEffect(() => {
    list();
  }, []);

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/chat/room/room/list',pageData.listOpt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        for(let i=0;i<response.data["data"]["info_arr"].length;i++){
          //방이름에서 내이름 제거
          let room_name=response.data["data"]["info_arr"][i]["a_name"];
          room_name=room_name.replace(","+user.user_name,"");
          room_name=room_name.replace(user.user_name+",","");
          response.data["data"]["info_arr"][i]["a_name"]=room_name;
          //방인원수
          response.data["data"]["info_arr"][i]["row_view_cnt"]=response.data["data"]["info_arr"][i].mem_arr.length;
        }
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goChatRoom=()=>{
    let selectedRows=listRef.current.getSelectedRows();
    if(selectedRows.length==0){
      alert("선택이 없습니다.");
      return false;
    }else if(selectedRows.length!=1){
      alert("한개만 선택해주세요.");
      return false;
    }
    props.closePopup();
    props.openOnePopup({
      "sort":"chat",
      "roomInfo":selectedRows[0],
      "title":selectedRows[0]["a_name"]
    });
  };

  const goChangeRoomName=()=>{
    if(changeNameData.roomName==""){
      alert("방이름 입력 필요.");
      return false;
    }
    if(changeNameData.roomInfo==null){
      alert("방선택 필요.");
      return false;
    }
    if(!confirm("방이름을 바꾸시겠습니까?")){
      return false;
    }
    let change_room_row_data={
      "a_seq":changeNameData.roomInfo.a_seq,
      "a_name":changeNameData.roomName
    };
    let room_change_opt={
      "data_arr":[change_room_row_data],
      "is_update":"1"
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/chat/room/room/write',room_change_opt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        list();
        setChangeNameData({...changeNameData,isShow:false,roomInfo:null,roomName:""});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const customCellRenderer=(params)=>{
    let valStr=params.value;
    if(params.key=="a_name"){
      valStr=(<a href="#" onClick={()=>{
        setTimeout(()=>{goChatRoom();},100);
      }}>{valStr}</a>);
    }else if(params.key=="row_view_unread"){
      if(params.rowData.unread_chat_tot){
        valStr=(<span className="text-red-500">{params.rowData.unread_chat_tot}</span>);
      }
    }else if(params.key=="row_view_change_name"){
      valStr=(<div className="text-center"><button className="btn btn-gray" >변경</button></div>);
    }
    return valStr;
  };

  return (
    <div>
      <PopMenu
        sort="room"
        closePopup={props.closePopup}
        openOnePopup={props.openOnePopup}
      ></PopMenu>
      <div className="mt-2">
        <ListComponent
          ref={listRef}
          infoArr={infoArr}
          xColumnArr={xColumnArr}
          customCellRenderer={customCellRenderer}
          gridOpt={gridOpt}
        ></ListComponent>
      </div>
      <div>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
      </div>
      <div className="btn-box-center pt-2">
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
      {changeNameData.isShow && 
      <div className="absolute bg-gray-500 p-5" style={{"left":"20%","top":"20%"}}>
        <div>
          이름변경
        </div>
        <div>
          <input type="text" className="text-cyan-900" value={changeNameData.roomName} ref={changeRoomNameRef}
            onChange={(e)=>{setChangeNameData({...changeNameData,roomName:e.target.value});}} />
        </div>
        <div className="btn-box-center pt-2">
          <button className="btn btn-gray" onClick={goChangeRoomName}>적용</button>
          <button className="btn btn-gray" onClick={()=>{setChangeNameData({...changeNameData,isShow:false});}} >취소</button>
        </div>
      </div>
      }
    </div>
  );
}
ListPage.defaultProps = {
  "closePopup":()=>{},
  "openOnePopup":()=>{},
  "callBack":()=>{},
}
export default ListPage;
