import TopMenu from 'components/common/test/menu/top_menu';
import SubMenu from '../menu.js';
import Datepicker from 'flowbite-datepicker/Datepicker';
import { useEffect,useState } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import {customHeaderFunc} from "./date_picker/custom_header";

function DesignWrite() {
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    const datepickerEl = document.getElementById('datepickerId');
    new Datepicker(datepickerEl, {
        // options
    }); 
  });

  return (
    <div>
      <TopMenu></TopMenu>
      <SubMenu></SubMenu>
      <h2 className="con-title" >Write</h2>
      <div>
        <p className="con-p-gray">Aa</p>
        <table className="write-table-div">
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>이름</th>
              <td>
                <input type="text" className="row-input"  placeholder="이름입력" />
              </td>
              <th>전화번호</th>
              <td>
                <input type="text" className="row-input"  placeholder="000-0000-0000" />
              </td>
            </tr>
            <tr>
              <th>아이디</th>
              <td>
                <input type="text" className="row-input"  placeholder="3글자이상" />
              </td>
              <th>비번</th>
              <td>
                <input type="password" className="row-input"  placeholder="5글자입력" />
              </td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>
                <input type="text" className="row-input"  placeholder="이메일입력" />
              </td>
              <th>로그인허용</th>
              <td>
                <select className="row-input">
                  <option value="">Choose a country</option>
                  <option value="US">United States</option>
                  <option value="CA">Canada</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>가입일</th>
              <td>
                <input id="datepickerId" type="text" className="row-input"  placeholder="0000-00-00" />
              </td>
              <th></th>
              <td>
                <DatePicker 
                  selected={startDate} 
                  onChange={(date:Date) => setStartDate(date)} 
                  locale={ko} 
                  dateFormat="yyyy-MM-dd"
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-box-center py-4">
          <button className="btn-l btn-line-gray" >등록</button>
          <button className="btn-l btn-line-gray" >취소</button>
        </div>
      </div>
    </div>
  );
}

export default DesignWrite;
