import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

function ViewPopupContent(props) {
  //props.rowData.a_user_name;
  const downloadFormRef=useRef();
  const imgStyle={
    maxWidth:"100%"
  };

  const goDownLoad=()=>{
    let pri_val=props.rowData.a_ymd+","+props.rowData.a_seq;
    downloadFormRef.current.action=process.env.REACT_APP_FILE_SERVER_URL+"/api/file/download/"+pri_val;
    downloadFormRef.current.submit();
  };

  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <td colSpan={2}>
                <div className="text-center">
                  {props.rowData.is_image=="1" &&
                    <a href="#" onClick={()=>{window.open(props.rowData.img_url);}}>
                      <img src={props.rowData.img_url} style={imgStyle} />
                    </a>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th>다운로드</th>
              <td>
                  <button className="btn btn-blue" onClick={()=>{goDownLoad();}} >다운로드</button>
                  <form id="download_form" ref={downloadFormRef} method="post" >
                    <input type="hidden" name="authorization" value={localStorage.lyg_mproject_token_id} />
                  </form>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>{props.rowData.a_oriname}</td>
            </tr>
            <tr>
              <th>크기</th>
              <td>{props.rowData.a_size}</td>
            </tr>
            <tr>
              <th>작성일</th>
              <td>{props.rowData.a_create_date}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
