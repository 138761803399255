import DateFunc from 'lib/lyg/date_func';

const checkVersion=()=>{
  if(localStorage.script_version_create_date==DateFunc.get_date_format(new Date(),"Y-m-d h")){
    return false;
  }
  window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/version/version/script_version',{})
  .then((response) => {
    if(response.data["result"]=="true"){
      if(localStorage.script_version!=response.data.data){
        localStorage.script_version=response.data.data;
        localStorage.script_version_create_date=DateFunc.get_date_format(new Date(),"Y-m-d h");
        window.location.reload();
      }
    }
  });
};

export default checkVersion;
