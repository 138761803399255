import { useEffect,useState,forwardRef,useImperativeHandle  } from 'react';
import DateFunc from 'lib/lyg/date_func';
import {customHeaderFunc} from 'components/common/date_picker/custom_header';
import {CustomInputSearch} from 'components/common/date_picker/custom_input';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

const SearchPeriodArea=forwardRef((props, ref) => {
  const [listOpt,setListOpt]=useState({
    "s_date_type":"",
    "s_start_date":"",
    "s_end_date":"",
    ...props.listOpt
  });
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setListOpt
  }));

  useEffect(() => {
    
  });
  
  const onSearchInputChanged=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let tmpListOpt={...listOpt};
    tmpListOpt[name] = value;
    tmpListOpt["now_page"]="1";
    setListOpt(tmpListOpt);
    props.list(tmpListOpt);
  };

  const onChangeDatePicker=(key,date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    let tmpListOpt={...listOpt};
    tmpListOpt[key] = date_str;
    setListOpt(tmpListOpt);
    props.list(tmpListOpt);
  };

  return (
    <div className="search-input-box">
      <select className="search-input search-input_select" name="s_date_type" value={listOpt.s_date_type} onChange={onSearchInputChanged} >
        {props.xColumnArr["order_id_col_arr"].map((item) => (
          <option value={item.value} key={item.value}>
            {item.text}
          </option>
        ))}
      </select>
      <DatePicker 
        wrapperClassName="w-24 pl-1"
        selected={listOpt.s_start_date!=""?new Date(listOpt.s_start_date):null} 
        onChange={(date) => {
          onChangeDatePicker("s_start_date",date);
        }}
        locale={ko} 
        dateFormat="yyyy-MM-dd"
        customInput={<CustomInputSearch />}
        renderCustomHeader={customHeaderFunc}
      />~
      <DatePicker 
        wrapperClassName="w-24"
        selected={listOpt.s_end_date!=""?new Date(listOpt.s_end_date):null} 
        onChange={(date) => {
          onChangeDatePicker("s_end_date",date);
        }}
        locale={ko} 
        dateFormat="yyyy-MM-dd"
        customInput={<CustomInputSearch />}
        renderCustomHeader={customHeaderFunc}
      />
      {props.children}
    </div>
  );
});

SearchPeriodArea.defaultProps = {
  xColumnArr:{},
  listOpt:{},
  list:()=>{},
};

export default SearchPeriodArea;
