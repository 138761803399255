import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ListComponent from 'components/common/list/list/list_component';
import Paging from 'components/common/list/paging/paging';
import getXcolumnJson from '../../xcolumn/list.js';
import PopMenu from 'pages/home/chat/x_include/pop_menu/menu';

function ListPage(props) {
  const xColumnArr=getXcolumnJson();
  const user = useSelector((state) => state.user);
  const listRef= useRef();
  const gridOpt={
    is_add_idx_num:false,
    is_add_checkbox:true,
    width:"auto",
    td_align:"center",
    table_align:"center",
    rowOnClick:(rowData,idx,e)=>{
      if(e.target.className!="chk_row"&&e.target.className!="btn btn-yellow"){
        
      }
    }
  };
  const [pageData, setPageData] = useState({
    listOpt:{
      "s_except_user_seq":[user.user_seq],
      ...xColumnArr.list_opt
    }
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });
  useEffect(() => {
    list();
  }, []);

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/user/list',pageData.listOpt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goChatRoom=()=>{
    let selectedRows=listRef.current.getSelectedRows();
    if(selectedRows.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    props.closePopup();
    props.callBack({
      "infoArr":selectedRows
    });
  };

  const customCellRenderer=(params)=>{
    let valStr=params.value;
    if(params.key=="row_view_thum"){
      //params.rowData
      valStr=(
        <a href="#" onClick={()=>{
          setTimeout(()=>{goChatRoom();},100);
        }}>👤</a>
      );
    }else if(params.key=="a_user_name"){
      valStr=(<a href="#" onClick={()=>{
        setTimeout(()=>{goChatRoom();},100);
      }}>{valStr}</a>);
    }
    return valStr;
  };

  return (
    <div>
      <PopMenu
        sort="user"
        closePopup={props.closePopup}
        openOnePopup={props.openOnePopup}
      ></PopMenu>
      <div className="mt-2">
        <ListComponent
          ref={listRef}
          infoArr={infoArr}
          xColumnArr={xColumnArr}
          customCellRenderer={customCellRenderer}
          gridOpt={gridOpt}
        ></ListComponent>
      </div>
      <div>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
      </div>
      <div className="btn-box-center pt-2">
        <button className="btn btn-yellow" onClick={()=>{goChatRoom();}}>선택대화</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}
ListPage.defaultProps = {
  "callBack":()=>{},
  "closePopup":()=>{},
  "openOnePopup":()=>{},
}

export default ListPage;
