import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

function ChatMemList(props) {
  const user = useSelector((state) => state.user);
  const chat = useSelector((state) => state.chat);

  return (
    <div className="chat_user_list_wrap">
      <div className="relative w-full h-full">
        <div>
          <h1>유저</h1>
          <button className="btn btn-line-gray absolute right-0 top-0" onClick={()=>{props.setIsMemShow(false)}}>닫기</button>
        </div>
        <div className="relative"  >
          {props.memInfoArr.map((item,idx)=>{
            let memRowClassName="con-span-black";
            for(let i=0;i<chat.memNowArr.length;i++){
              if(chat.memNowArr[i].user_seq==item.a_user_seq){
                memRowClassName=" text-sky-600";
              }
            }

            let owner_text="";
            if(item.a_is_owner=="1"){
              owner_text="👑";
            }

            let iam_name_text="";
            if(item.a_user_seq==user.user_seq){
              iam_name_text=(<span className="text-sky-600 font-bold" >(나)</span>);
            }
            
            return (
              <div key={idx} className={memRowClassName}>
                {idx+1}.  {item.a_user_name} {iam_name_text} {owner_text}
              </div>
            );
          })}
        </div>
        <div className="btn-box-center w-full absolute left-0 bottom-1">
          <button className="btn btn-line-gray" onClick={()=>{props.openOnePopup("find");}}>초대</button>
          <button className="btn btn-line-gray" onClick={()=>{props.outChatRoom();}}>나가기</button>
        </div>
      </div>
    </div>
  );
}
ChatMemList.defaultProps = {
  "memInfoArr":[],
  "setIsMemShow":()=>{},
  "outChatRoom":()=>{},
  "openOnePopup":()=>{},
}
export default ChatMemList;
