import React,{ useEffect } from 'react';
import {hmenu,smenu} from './data/menu_arr';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AuthFunc from 'lib/lyg/auth';

function SubMenu(props){
  const user = useSelector((state) => state.user);
  useEffect(() => {
    
  }, []);

  let now_hkey="";
  for (var skey in smenu) {
    if(window.location.pathname===smenu[skey]["url"]){
      now_hkey=smenu[skey]["hmenu"];
      document.title = smenu[skey]["name"];
    }
  }
  
  let showMenuCount=0;
  const sMenuArr= Object.entries(smenu).map(([key,menu_data])=>{
    if(menu_data["hmenu"]!==now_hkey || menu_data["is_show"]!=="1"){
      return false;
    }

    let smenu_class_name="sub-menu-btn";
    let smenu_split_class_name="sub-menu-split-line";
    if(window.location.pathname===menu_data["url"]){
      smenu_class_name="sub-menu-btn active";
    }

    if(!AuthFunc.checkMenuAuth({"user":user,"sort":"smenu","menuKey":key})){
      smenu_class_name+=" hidden";
      smenu_split_class_name+=" hidden";
    }else{
      showMenuCount++;
    }

    return (
      <React.Fragment key={key}>
        <Link className={smenu_class_name} to={menu_data["url"]} >{menu_data["name"]}</Link>
        <span className={smenu_split_class_name}>|</span>
      </React.Fragment>
    );
  });
  let sClassName="header-wrap-sub-wrap";
  if(showMenuCount==0){
    sClassName+=" hidden";
  }

  return (
    <div className={sClassName}>
      <div className="header-wrap-sub">
        <div className="header-item">
            {sMenuArr}
        </div>
      </div>
    </div>
  );
}

export default SubMenu;
