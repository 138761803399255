import XcolumnFunc from "components/common/xcolumn/xcolumn/xcolumn_func";

const xColumnArr=XcolumnFunc.getDefaultXcolumn();

xColumnArr.table="authg";
xColumnArr.x_column_list_arr={
  //"a_ymd":{"name":"board_ymd","width":"100","auto":""},
};

xColumnArr.select_arr=XcolumnFunc.getSortByTable({"table":xColumnArr["table"]});

export default xColumnArr;