import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from 'store/user/user';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';

function WritePopupContent(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const [rowData,setRowData]=useState({
    "a_seq":"",
    "a_corp_seq":"1",
    "a_user_id":"",
    "a_user_name":"",
    "a_user_grade":"user",
    "a_is_login":"1",
  });

  useEffect(()=>{
    if(props.isUpdate){
      let changeRowData={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const goWrite=useCallback( e => {
    if(rowData.a_user_id==""){
      alert("아이디 입력이 필요합니다.");
      return false;
    }
    if(!props.isUpdate){
      if(rowData.a_user_pw==""){
        alert("암호 입력이 필요합니다.");
        return false;
      }
    }
    if(rowData.a_user_name==""){
      alert("이름 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }


    let formJsonData={
      "data_arr":[rowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(props.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/user/write',formJsonData)
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.user_data_create_date="";
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);


  const user_grade_arr={"user":"유저","guest":"게스트","master":"마스터"};
  const user_grade_opt_arr=Object.entries(user_grade_arr).map(([key,name])=>{
    return (
      <option key={key} value={key} >{name}</option>
    );
  });

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="120px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>아이디</th>
              <td>
                <input type="text" className="row-input" name="a_user_id" value={rowData.a_user_id} onChange={handleInputChange} placeholder="아이디" />
              </td>
            </tr>
            <tr>
              <th>비밀번호</th>
              <td>
                <input type="password" className="row-input"  name="a_user_pw" onChange={handleInputChange} />
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                <input type="text" className="row-input" name="a_user_name" value={rowData.a_user_name} onChange={handleInputChange} placeholder="이름입력" />
              </td>
            </tr>
            <tr>
              <th>등급</th>
              <td>
                <select className="row-input" name="a_user_grade" value={rowData.a_user_grade} onChange={handleInputChange} onKeyUp={(e)=>{if(e.key === 'Enter'){goWrite();}}} >
                  {user_grade_opt_arr}
                </select>
              </td>
            </tr>
            <tr>
              <th>로그인허용</th>
              <td>
                <select className="row-input" name="a_is_login" value={rowData.a_is_login} onChange={handleInputChange} >
                  <option value="">미허용</option>
                  <option value="1">허용</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
