
function ViewPopupContent(props) {
  //props.rowData.a_user_name;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>상호</th>
              <td>{props.rowData.a_company_name}</td>
            </tr>
            <tr>
              <th>대표자</th>
              <td>{props.rowData.a_represent_name}</td>
            </tr>
            <tr>
              <th>사업자번호</th>
              <td>{props.rowData.a_company_busin_num}</td>
            </tr>
            <tr>
              <th>작성일</th>
              <td>{props.rowData.a_create_date}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewPopupContent;
