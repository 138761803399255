import getXcolumnJson from '../../xcolumn/list.js';
import FileListWriteFrame from 'pages/home/basic/file/frame/write/index';
import ReplyListWriteFrame from 'pages/home/basic/reply/frame/list_write/index';
import { useSelector, useDispatch } from 'react-redux';
import AuthFunc from 'lib/lyg/auth';

function ViewPopupContent(props) {
  const user = useSelector((state) => state.user);
  //props.rowData.a_user_name;
  const xColumnArr=getXcolumnJson();
  xColumnArr.select_arr["a_project_seq"]=xColumnArr.func.getProjectArr();
  xColumnArr.select_arr["a_project_seq"].unshift({"value":"","text":"선택없음"});

  let project_name="";
  for(let i=0;i<xColumnArr["select_arr"]["a_project_seq"].length;i++){
    if(xColumnArr["select_arr"]["a_project_seq"][i]["value"]==props.rowData.a_project_seq){
      project_name=xColumnArr["select_arr"]["a_project_seq"][i]["text"];
    }
  }
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>프로젝트</th>
              <td>{project_name}</td>
            </tr>
            <tr>
              <th>제목</th>
              <td>{props.rowData.a_title}</td>
            </tr>
            <tr>
              <th>주최자</th>
              <td>{props.rowData.a_writer}</td>
            </tr>
            <tr>
              <th>참가자</th>
              <td>{props.rowData.a_join_member}</td>
            </tr>
            <tr>
              <th>회의일</th>
              <td>{props.rowData.a_date}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                {
                  props.rowData.a_content && props.rowData.a_content.split('\n').map( (line,idx) => {
                    return (<span key={idx}>{line}<br/></span>)
                  })
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center">
        {AuthFunc.checkTableAuthXcolumn({"user":user,"authKey":"update","data_arr":[props.rowData],"xColumnArr":xColumnArr}) &&
          <button className="btn btn-line-gray" onClick={()=>{props.closePopup();props.openOnePopup(true);}}>수정</button>
        }
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
      {
      props.rowData.a_seq!="" && 
      <div>
      <FileListWriteFrame
        listOpt={
        {
          s_par_id:xColumnArr.table,
          s_par_seq:props.rowData.a_ymd+","+props.rowData.a_seq,
          s_start_date:props.rowData.a_date
        }
        }
        isView={true}
      ></FileListWriteFrame>

      <ReplyListWriteFrame
        listOpt={
        {
          s_par_id:xColumnArr.table,
          s_par_seq:props.rowData.a_ymd+","+props.rowData.a_seq,
          s_start_date:props.rowData.a_date
        }
        }
      ></ReplyListWriteFrame>
      </div>
      }
    </div>
  );
}

export default ViewPopupContent;
