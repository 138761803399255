
function ViewPopupContent(props) {
  //props.rowData.a_user_name;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>타입</th>
              <td>{props.rowData.a_type}</td>
            </tr>
            <tr>
              <th>구분</th>
              <td>{props.rowData.a_id}</td>
            </tr>
            <tr>
              <th>등급</th>
              <td>{props.rowData.a_user_grade}</td>
            </tr>
            <tr>
              <th>CRUD</th>
              <td>{props.rowData.a_crud}</td>
            </tr>
            <tr>
              <th>허용여부</th>
              <td>{props.rowData.a_is_allow}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewPopupContent;
