import { Provider } from 'react-redux';
import store from 'store/store';
import { RouterProvider } from "react-router-dom";
import router from "router/index";
//import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
// import io from 'socket.io-client';
// window.web_socket = io(process.env.REACT_APP_Chat_SERVER_URL,{
//   cors: {
//     origin: "*",
//   }
// });

let dark_mode="";
if (localStorage.theme === 'dark' ) {
  dark_mode="dark";
} else {
  dark_mode="light";
}

function InitApp() {
  let dark_mode_class=dark_mode;

  return (
    <div id="main_wrap" className={dark_mode_class}>
      <div className="main_body">
        <div className="main_body_box">
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </div>
      </div>
    </div>
  );
}

export default InitApp;
