import TopMenu from 'components/common/test/menu/top_menu';
import SubMenu from '../menu.js';
import React, { useState, useEffect,useRef } from 'react';

function DBPage() {
  const [infoArr, setInfoArr] = useState([]);
  useEffect(() => {
    requestList();
  },[]);
  const requestList=()=>{
    window.axios.post( process.env.REACT_APP_API_URL+'/api/test/mytest/list',{ 
      test_name: 'Sewon',
    }).then((response) => {
      setInfoArr(response.data['data']);
    });
  };
  const requestTest=()=>{
    window.axios.post( process.env.REACT_APP_API_URL+'/api/test/mytest/test',
    { 
      test_name: 'Sewon',
    }
    ).then((response) => { 
      console.log(response.data);
    });
  };
  const requestWrite=()=>{
    window.axios.post( process.env.REACT_APP_API_URL+'/api/test/mytest/write',{ 
      test_name: 'Sewon',
    },).then((response) => { 
      console.log(response.data);
      requestList();
    });
  };

  return (
    <div>
      <TopMenu></TopMenu>
      <SubMenu></SubMenu>
      <h2 className="text-2xl text-gray-900 dark:text-gray-200" >DB</h2>
      <div className="btn-box-center">
        <button className="btn btn-brown" onClick={()=>{requestTest();}}>테스트 요청</button>
        <button className="btn btn-brown" onClick={()=>{requestList();}}>List</button>
        <button className="btn btn-brown" onClick={()=>{requestWrite();}}>등록</button>
      </div>
      <div className="con-p-gray">{infoArr.length}</div>
      <div id="result_data_div">
          {infoArr.map(user => {
              return (<div className="con-p-gray" key={user.seq}>
                  {user.seq} {user.test_name}
              </div>)
          })}
      </div>
    </div>
  );
}

export default DBPage;
