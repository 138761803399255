import TopMenu from 'components/common/test/menu/top_menu';
import SubMenu from '../menu.js';

function DesignLayout() {
  return (
    <div>
      <TopMenu></TopMenu>
      <SubMenu></SubMenu>
      <div className="top-nav-wrap">
        <button className="top-nav-item">학원관리</button>
        <button className="top-nav-item">진단테스트</button>
        <button className="top-nav-item active">원생관리</button>
        <button className="top-nav-item">훈련학습</button>
      </div>
      <div className="content-wrap">
        <div className="content-left-wrap">
          <div className="left-nav-wrap">
            <button className="left-nav-item active">원생관리</button>
            <button className="left-nav-item">원생수업일지</button>
            <button className="left-nav-item">상담관리</button>
          </div>
        </div>
        <div className="content-center-wrap"></div>
      </div>
    </div>
  );
}

export default DesignLayout;
