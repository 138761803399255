import { useState } from 'react';
import AuthFunc from 'lib/lyg/auth';
import { useSelector, useDispatch } from 'react-redux';

function SearchUserSelect(props){
  const user = useSelector((state) => state.user);
  const [userSeq,setUserSeq] = useState(props.s_user_seq);
  let user_data_arr=[];
  if(AuthFunc.checkTableAuth({"user":user,"tableKey":props.table,"authKey":"list"})){
    if(localStorage.user_data_arr){
      user_data_arr=JSON.parse(localStorage.user_data_arr);
    }
  }else{
    user_data_arr=[{"value":user.user_seq,"text":user.user_name}];
  }
  
  let userOptions=user_data_arr.map((col_data,idx)=>{
    return (
      <option key={idx} value={col_data["value"]}>{col_data["text"]}</option>
    );
  });

  return (
    <>
      <select className="search-input" value={userSeq} onChange={(e)=>{props.onChange(e);setUserSeq(e.target.value);}}>
        <option value="">전체</option>
        {userOptions}
      </select>
    </>
  );
}

SearchUserSelect.defaultProps = {
  table:"",
  s_user_seq:"",
  onChange:(e)=>{},
};

export default SearchUserSelect;
