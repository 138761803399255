
function ViewPopupContent(props) {
  //props.rowData.a_user_name;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>부모구분</th>
              <td>{props.rowData.a_par_id}</td>
            </tr>
            <tr>
              <th>부모컬럼</th>
              <td>{props.rowData.a_par_col}</td>
            </tr>
            <tr>
              <th>내용</th>
              <td>{props.rowData.a_value}</td>
            </tr>
            <tr>
              <th>설명</th>
              <td>{props.rowData.a_text}</td>
            </tr>
            <tr>
              <th>순번</th>
              <td>{props.rowData.a_order_num}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewPopupContent;
