import XcolumnFunc from "components/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData={
    table:"reply",
    list_id:"reply_list",
    x_column_list_arr:[
      // {"key":"a_par_id","name":"부모구분","width":"120","is_show":"1"},
      // {"key":"a_par_seq","name":"부모키값","width":"120","is_show":"1"},
      {"key":"a_writer","name":"작성자","width":"100","is_show":"1"},
      {"key":"a_content","name":"내용","width":"200","is_show":"1"},
      {"key":"a_create_date","name":"작성일","width":"180","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'',
      'order_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
    }
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;