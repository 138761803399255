import HoemHeader from 'components/home/header/header';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import LayerPopup from 'components/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view/index';
import { useSelector, useDispatch } from 'react-redux';
import Paging from 'components/common/list/paging/paging';
import ListAggrid from 'components/common/list/aggrid/list';
import getXcolumnJson from '../xcolumn/list.js';

function UserListPage() {
  const xColumnArr=getXcolumnJson();
  const user = useSelector((state) => state.user);
  const listAggridRef= useRef();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });
  
  useEffect(() => {
    //list();
  }, []);

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/project/project/project/list',pageData.listOpt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( e => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }else if(selected_row.length>=2){
      alert("한개만 선택해주세요.");
      return false;
    }
    let tmp_delete_row=selected_row[0];
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/project/project/project/delete',{
      data_arr:[tmp_delete_row],
    },).then((response) => {
      if(response.data["result"]==="true"){
        localStorage.project_data_create_date="";
        list();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const openOnePopup=(isUpdate,sort)=>{
    if(isUpdate==undefined){isUpdate=false;}
    if(sort==undefined){sort="write";}
    let tmp_rowData={};
    let pop_title="등록";
    if(isUpdate){
      pop_title="수정";
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "title":pop_title,
      "sort":sort
    });
  };

  const cellClickedListener = useCallback( params => {
    var key=params.colDef.field;
    if(key=="a_project_name"){
      openOnePopup(true,"view");
    }
  }, []);

  const cellRenderer= useCallback( params => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="a_project_name"){
      render_str=<a href="#">{render_str}</a>;
    }
    return render_str;
  }, []);

  return (
    <div>
      <HoemHeader></HoemHeader>
      <div className="main-content-wrap">
        <div className="main-content-div">
          <div className="btn-box-left">
            <button className="btn btn-blue" onClick={()=>{openOnePopup(false);}} >등록</button>
            <button className="btn btn-blue" onClick={()=>{openOnePopup(true);}} >수정</button>
            <button className="btn btn-round-orange" onClick={goDelete}>삭제</button>
            <button className="btn btn-blue" onClick={()=>{list({"now_page":1});}} >검색</button>
          </div>
          <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{...xColumnArr}}
            list={list}
            gridOpt={{
              fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
              is_idx_num:false,
              is_add_checkbox:false,
              floatingFilter:true,
              onGridReady:()=>{
                list();
              },
              onCellClicked:cellClickedListener,
              cellRenderer:cellRenderer
            }}
          ></ListAggrid>
          <div>
            <Paging now_page={pageData.listOpt.now_page}
              num_per_page={pageData.listOpt.num_per_page}
              total_rec={countInfo.tot} 
              onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
          </div>
        </div>
      </div>
      {popupData.isOpen && 
        <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
          width={popupData.width} height={popupData.height} >
          {popupData.sort==="write"&&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></WritePopupContent>
          }
          {popupData.sort==="view"&&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></ViewPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
}

export default UserListPage;
