import HoemHeader from 'components/home/header/header';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import LayerPopup from 'components/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view/index';
import Paging from 'components/common/list/paging/paging';
import getXcolumnJson from '../xcolumn/list.js';
import ListAggrid from 'components/common/list/aggrid/list';
import SearchArea from './area/search';

function UserListPage() {
  const xColumnArr = useMemo(() => {
    return getXcolumnJson();
  },[])
  const listAggridRef= useRef();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  useEffect(() => {
    
  }, []);

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/authg/authg/list',pageData.listOpt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( e => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/authg/authg/delete',{
      data_arr:selected_row,
    },).then((response) => {
      if(response.data["result"]==="true"){
        list();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const openOnePopup=(isUpdate,sort)=>{
    if(isUpdate==undefined){isUpdate=false;}
    if(sort==undefined){sort="write";}
    let tmp_rowData={};
    let pop_title="등록";
    if(isUpdate){
      pop_title="수정";
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "title":pop_title,
      "sort":sort
    });
  };

  const goWriteArr=useCallback( e => {
    let selected_row=listAggridRef.current.getRows();
    if(selected_row.length===0){
      alert("데이터 없음.");
      return false;
    }
    if(!confirm("저장 하시겠습니까?")){
      return false;
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/authg/authg/write',{
      data_arr:selected_row,
      //is_update:"1",
    },).then((response) => {
      if(response.data["result"]==="true"){
        list();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const goWriteAllInit=useCallback( () => {
    let tmp_init_form={
      "s_type":pageData.listOpt.s_type,
      "s_id":pageData.listOpt.s_id,
      "s_grade":pageData.listOpt.s_grade,
      "s_crud":pageData.listOpt.s_crud,
    };
    if(!confirm("초기화 하시겠습니까?")){
      return false;
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/authg/authg/write_init_all',tmp_init_form)
    .then((response) => {
      if(response.data["result"]==="true"){
        list();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const onCellClicked=useCallback((params)=>{
    if(params.colDef.field=="a_is_allow"){
      if(params.data["a_is_allow"]=="1"){
        params.data["a_is_allow"]="";
      }else{
        params.data["a_is_allow"]="1";
      }
      listAggridRef.current.updateRowData(params.data);
    }
  },[]);

  const cellRenderer=useCallback((params)=>{
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="a_is_allow"){
      if(render_str=="1"){
        render_str=(<button className="btn btn-round-green">허용</button>);
      }else{
        render_str=(<button className="btn btn-red">미허용</button>);
      }
    }
    
    return render_str;
  },[]);

  return (
    <div>
      <HoemHeader></HoemHeader>
      <div className="main-content-wrap">
        <div className="main-content-div">
          <SearchArea pageData={pageData} xColumnArr={xColumnArr} list={list}></SearchArea>
          <div className="btn-box-left">
            {/* <button className="btn btn-blue" onClick={()=>{openOnePopup(true,"view");}} >보기</button>
            <button className="btn btn-blue" onClick={()=>{openOnePopup(false);}} >등록</button>
            <button className="btn btn-blue" onClick={()=>{openOnePopup(true);}} >수정</button>
            <button className="btn btn-round-orange" onClick={goDelete}>삭제</button> */}
            <button className="btn btn-blue" onClick={()=>{goWriteAllInit();}} >모두초기화</button>
            <button className="btn btn-blue" onClick={()=>{goWriteArr();}} >모두저장</button>
          </div>
          <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{...xColumnArr}}
            list={list}
            gridOpt={{
              fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
              height:(window.innerHeight/2+20),
              onGridReady:()=>{
                list();
              },
              onCellClicked:onCellClicked,
              cellRenderer:cellRenderer,
            }}
          ></ListAggrid>
          
          <div>
            <Paging now_page={pageData.listOpt.now_page}
              num_per_page={pageData.listOpt.num_per_page}
              total_rec={countInfo.tot} 
              onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
          </div>
         </div>
      </div>
      {popupData.isOpen && 
        <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
          width={popupData.width} height={popupData.height} >
          {popupData.sort==="write"&&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></WritePopupContent>
          }
          {popupData.sort==="view"&&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></ViewPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
}

export default UserListPage;
