import {
  createBrowserRouter,
} from "react-router-dom";

import HomePages from './home/home_pages';
import TestPages from './test/test_pages';

const router = createBrowserRouter([
  ...HomePages,
  ...TestPages
]);

export default router;