import XcolumnFunc from "components/common/xcolumn/xcolumn/xcolumn_func";
import getAuthSortData from './data/auth_sort_data';
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData={
    x_column_list_arr:[
      {"key":"a_user_grade","name":"회원등급","width":"120","is_show":"1"},
      {"key":"a_type","name":"타입","width":"110","is_show":"1"},
      {"key":"a_id","name":"구분","width":"120","is_show":"1"},
      {"key":"a_crud","name":"CRUD","width":"100","is_show":"1"},
      {"key":"a_is_allow","name":"허용","width":"110","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':300,
      'order_id':'',
      'order_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      's_type':[],
      's_id':[],
      's_grade':[],
      's_crud':[],
    }
  };
  initData.table="authg";
  initData.list_id="authg_list";
  initData.pri_col_arr=["a_type","a_id","a_user_grade","a_crud"];
  const xColumnArr=XcolumnFunc.getDefaultXcolumn(initData);

  xColumnArr.authSortData=getAuthSortData();
  delete xColumnArr.select_arr["a_is_allow"];
  xColumnArr.select_arr["a_id"]=[
    ...xColumnArr.authSortData["table_ids"],
    ...xColumnArr.authSortData["hmenu_ids"],
    ...xColumnArr.authSortData["smenu_ids"],
  ];
  return xColumnArr;
};

export default getXcolumnJson;