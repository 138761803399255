import DateFunc from 'lib/lyg/date_func';

const getSortFunc=()=>{
  if(localStorage.sort_data_create_date==DateFunc.get_date_format(new Date(),"Y-m-d h")){
    return false;
  }
  const formJsonData={
      
  };
  window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/sort/sort/get_front_sort',formJsonData)
  .then((response) => {
    if(response.data["result"]=="true"){
      localStorage.sort_data_arr=JSON.stringify(response.data.data["sort_data_arr"]);
      localStorage.sort_data_create_date=DateFunc.get_date_format(new Date(),"Y-m-d h");
    }
  });
  return true;
};
export default getSortFunc;