import { useState } from 'react';

function PopMenu(props) {
  const [sort,setSort]=useState(props.sort);
  const movePage=(sort)=>{
    props.closePopup();
    props.openOnePopup({
      "sort":sort,
      "roomInfo":null,
    });
  };

  const menuArr=[
    {"sort":"user","name":"유저"},
    {"sort":"room","name":"채팅방"},
  ];

  const menuRenders=menuArr.map((item,idx)=>{
    let menuRowClassName="btn btn-line-gray";
    if(item.sort==props.sort){
      menuRowClassName+=" text-yellow-900 dark:text-yellow-100";
    }
    return (
      <button key={idx} className={menuRowClassName} onClick={()=>{movePage(item.sort);}}>
        {item.name}
      </button>
    );
  });

  return (
    <div>
      <div className="btn-box-center pt-2">
        {menuRenders}
      </div>
    </div>
  );
}
PopMenu.defaultProps = {
  "sort":"user",
  "closePopup":()=>{},
  "openOnePopup":()=>{},
}
export default PopMenu;
