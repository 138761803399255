//import {CustomInputWrite} from 'components/common/date_picker/custom_input';
//customInput={<CustomInputWrite />}
import { forwardRef } from 'react';

export const CustomInputWrite = forwardRef(({ value, onClick }, ref) => (
  <button className="row-input h-10" readOnly onClick={onClick} ref={ref}>
    {value}
  </button>
));

export const CustomInputSearch = forwardRef(({ value, onClick }, ref) => (
  <button className="search-input w-full h-7" readOnly onClick={onClick} ref={ref}>
    {value}
  </button>
));