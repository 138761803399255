import { Link,useNavigate } from 'react-router-dom';
import river_img from 'img/background/boat_river.png';
import person_img from 'img/login/person.png';
import shild_img from 'img/login/shild.png';
import { useRef, useEffect } from 'react';
import DarkModeBtn from 'components/common/dark_mode/dark_mode';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/user/user';

function LoginPage() {
  const navigate = useNavigate();
  const inputIdRef = useRef();
  const inputPwRef = useRef();
  const dispatch = useDispatch();
  
  useEffect(() => {
    document.title = "로그인";
    inputIdRef.current.focus();
    checkLogin();
  }, []);

  const goLogin=()=>{
    if(inputIdRef.current.value===""){
      alert("아이디 입력필요.");
      return false;
    }
    if(inputPwRef.current.value===""){
      alert("암호 입력필요.");
      return false;
    }

    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/login/login',{ 
      id: inputIdRef.current.value,
      pw: inputPwRef.current.value,
    },).then((response) => {
      if(response.data["result"]==="true"){
        localStorage.setItem('lyg_mproject_token_id', response.data.data["token_id"]);
        window.axios.defaults.headers.common['Authorization'] = localStorage.lyg_mproject_token_id;
        var client_info=response.data.data["client_info"];
        dispatch(setUser(client_info));
        if(client_info["user_grade"]=="guest"){
          navigate("/");
        }else{
          navigate("/home/report/list");
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const checkLogin=()=>{
    if(localStorage.lyg_mproject_token_id){
      window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/login/check_login',{ 
        token_id: localStorage.lyg_mproject_token_id,
      },).then((response) => {
        if(response.data["result"]==="true"){
          var client_info=response.data.data["client_info"];
          dispatch(setUser({
            user_id:client_info["user_id"],
            user_seq:client_info["user_seq"],
            user_name:client_info["user_name"],
          }));
          navigate("/");
        }
      });
    }
  };

  return (
    <div>
      <div className="flex justify-center pt-6" >
        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md bg-gray-100 dark:bg-gray-800 dark:border-gray-700">
          <img src={river_img} alt="리버" />
          <table className="table-auto w-full mt-3">
            <tbody>
              <tr>
                <th className="text-center">
                  <img src={person_img} className="inline-block" alt="아이디" />
                </th>
                <td>
                  <input type="text" className="w-11/12 px-3 py-1.5 text-base text-gray-900 focus:border-lime-700 bg-gray-100 border-neutral-300
                    dark:bg-gray-900 dark:text-slate-200" placeholder="아이디" autoComplete='false' ref={inputIdRef} />
                </td>
              </tr>
              <tr>
                <th className="text-center">
                  <img src={shild_img} className="inline-block" alt="암호" />
                </th>
                <td>
                  <input type="password" className="w-11/12 px-3 py-1.5 text-base text-gray-900 focus:border-lime-700 bg-gray-100 border-neutral-300
                    dark:bg-gray-900 dark:text-slate-200" placeholder="비밀번호" autoComplete='false' ref={inputPwRef} onKeyUp={(e)=>{if(e.key === 'Enter'){goLogin();}}} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="btn-box-center py-2">
            <button className="btn-l btn-yellow" onClick={goLogin} >로그인</button>
            <button className="btn-l btn-blue" onClick={()=>{navigate("/join");}}>회원가입</button>
          </div>
          <div className="btn-box-center py-2">
            <Link className="btn btn-round-green" to="/">Home</Link>
            <DarkModeBtn></DarkModeBtn>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
