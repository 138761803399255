//import FileListWriteFrame from 'pages/home/basic/file/frame/write/index';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import LayerPopup from 'components/common/popup/layer/layer_popup';
import WritePopupContent from 'pages/home/basic/file/popup/write/index';
import ViewPopupContent from 'pages/home/basic/file/popup/view/index';
import Paging from 'components/common/list/paging/paging';
import ListComponent from "components/common/list/list/list_component";
import { PropertyKeys } from 'ag-grid-community';

function FileListWriteFrame(props) {
  const listComponentRef = useRef();
  const downloadFormRef=useRef();
  const [pageData, setPageData] = useState({
    listOpt:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_start_date':props.listOpt.s_start_date,
      's_end_date':'',
      's_par_id':props.listOpt.s_par_id,
      's_par_seq':props.listOpt.s_par_seq,
      's_sort1':props.listOpt.s_sort1?props.listOpt.s_sort1:"",
      's_sort2':props.listOpt.s_sort2?props.listOpt.s_sort2:"",
      's_sort3':props.listOpt.s_sort3?props.listOpt.s_sort3:"",
    }
  });
  const write_init_data={
    "a_par_id":props.listOpt.s_par_id,
    "a_par_seq":props.listOpt.s_par_seq,
    "a_sort1":props.listOpt.s_sort1,
    "a_sort2":props.listOpt.s_sort2,
    "a_sort3":props.listOpt.s_sort3,
  };
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const xColumnArr = {
    x_column_list_arr:[
      {"key":"row_view_thum","name":"썸네일","width":"100"},
      {"key":"a_oriname","name":"파일명","width":"140"},
      {"key":"row_view_down","name":"다운","width":"70"},
      {"key":"a_size","name":"크기","width":"100"},
      {"key":"a_sort3","name":"구분","width":"100"},
      {"key":"a_writer","name":"작성자","width":"100"},
      {"key":"a_create_date","name":"작성일","width":"180"},
    ]
  };
  const customCellRenderer=(params)=>{
    let row_val="";
    if(params.rowData[params.key]!=undefined){
      row_val=params.rowData[params.key];
    }
    if(params.key=="row_view_thum"){
      if(params.rowData["is_image"]=="1"){
        row_val=
          <div className="text-center">
            <a href="#" onClick={()=>{setTimeout(()=>{openOnePopup(true,"view");},100);}} >
              <img src={params.rowData.thum_img_url} style={{height:25}} />
            </a>
          </div>;
      }
    }else if(params.key=="a_oriname"){
      row_val=
        <a href="#" onClick={()=>{setTimeout(()=>{openOnePopup(true,"view");},100);}} >
          {row_val}
        </a>;
    }else if(params.key=="row_view_down"){
      row_val=
        <div className="text-center">
          <button className="btn-s btn-round-green" onClick={()=>{setTimeout(()=>{goDownLoad();},100);}} >
            다운
          </button>
        </div>;
    }
    return row_val;
  };
  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"700px",
    height:"70%",
  });

  useEffect(() => {
    list();
  }, []);

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/file/file/list',pageData.listOpt)
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        listComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( e => {
    let selected_row=listComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }else if(selected_row.length>=2){
      alert("한개만 선택해주세요.");
      return false;
    }
    let tmp_delete_row={
      "a_ymd":selected_row[0]["a_ymd"],
      "a_seq":selected_row[0]["a_seq"],
    };
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/file/file/delete',{ 
      data_arr:[tmp_delete_row],
    },).then((response) => {
      if(response.data["result"]==="true"){
        list();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const openOnePopup=(isUpdate,sort)=>{
    if(isUpdate==undefined){isUpdate=false;}
    if(sort==undefined){sort="write";}
    let tmp_rowData={};
    let pop_title="등록";
    if(isUpdate){
      pop_title="수정";
      let selected_row=listComponentRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "title":pop_title,
      "sort":sort
    });
  };

  const goDownLoad=()=>{
    let selected_row=listComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }else if(selected_row.length>=2){
      alert("한개만 선택해주세요.");
      return false;
    }
    let pri_val=selected_row[0]["a_ymd"]+","+selected_row[0]["a_seq"];
    downloadFormRef.current.action=process.env.REACT_APP_FILE_SERVER_URL+"/api/file/download/"+pri_val;
    downloadFormRef.current.submit();
  };

  return (
    <div>
      <h1 className="con-p-gray">첨부파일 ({countInfo["tot"]} 건) </h1>
      <div className="btn-box-left">
        {props.isView==false&&
          <>
            <button className="btn btn-blue" onClick={()=>{openOnePopup(false);}} >등록</button>
            <button className="btn btn-round-orange" onClick={goDelete}>삭제</button>
          </>
        }
      </div>
      <ListComponent 
        ref={listComponentRef} 
        infoArr={infoArr} 
        xColumnArr={xColumnArr} 
        customCellRenderer={customCellRenderer} ></ListComponent>
      <div>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
      </div>
      <form id="download_form" ref={downloadFormRef} method="post" >
        <input type="hidden" name="authorization" value={localStorage.lyg_mproject_token_id} />
      </form>
      {popupData.isOpen && 
        <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
          width={popupData.width} height={popupData.height} >
          {popupData.sort==="write"&&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
             init_data={write_init_data}></WritePopupContent>
          }
          {popupData.sort==="view"&&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></ViewPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
}

FileListWriteFrame.defaultProps = {
  "isView":false,
  "listOpt":{
    "s_start_date":"",
    's_par_id':"",
    's_par_seq':"",
    's_sort1':"",
    's_sort2':"",
    's_sort3':"",
  }
}

export default FileListWriteFrame;