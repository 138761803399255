import TopMenu from 'components/common/test/menu/top_menu';
import SubMenu from './menu.js';

function DesignMain() {
  return (
    <div>
      <TopMenu></TopMenu>
      <SubMenu></SubMenu>
      <h2 className="text-2xl text-gray-900 dark:text-gray-200" >디자인메인</h2>
    </div>
  );
}

export default DesignMain;
