import XcolumnFunc from "components/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData={
    table:"prj",
    list_id:"project_list",
    x_column_list_arr:[
      {"key":"a_project_name","name":"프로젝트","width":"120","is_show":"1"},
      {"key":"a_start_date","name":"시작","width":"120","is_show":"1"},
      {"key":"a_end_date","name":"종료","width":"100","is_show":"1"},
      {"key":"a_create_date","name":"작성일","width":"180","is_show":"1"},
      {"key":"a_update_date","name":"수정일","width":"180","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_project_name DESC',
      'order_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
    }
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;