import { useSelector, useDispatch } from 'react-redux';
import { setDark } from 'store/dark/dark';
import {  useEffect } from 'react';

function DarkModeBtn() {
  const dispatch = useDispatch();
  const dark = useSelector((state) => state.dark);
  
  useEffect(()=>{
    dispatch(setDark(localStorage.theme));
  },[]);
  const handleClick=()=>{
    if (localStorage.theme === 'dark' ) {
      document.getElementById("main_wrap").classList.remove('dark');
      document.getElementById("modal").classList.remove('dark');
      document.body.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }else{
      document.getElementById("main_wrap").classList.add('dark');
      document.getElementById("modal").classList.add('dark');
      document.body.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    }
    dispatch(setDark(localStorage.theme));
  }
  return (
    <>
      <button className="btn btn-round-orange"
        onClick={handleClick}>
        다크
      </button>
    </>
  );
}

export default DarkModeBtn;
