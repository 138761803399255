import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user/user'
import darkReducer from './dark/dark'
import chatReducer from './chat/chat'

export default configureStore({
  reducer: {
    user:userReducer,
    dark:darkReducer,
    chat:chatReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})