
function ViewPopupContent(props) {
  //props.rowData.a_user_name;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>이름</th>
              <td>{props.rowData.a_user_name}</td>
            </tr>
            <tr>
              <th>아이디</th>
              <td>{props.rowData.a_user_id}</td>
            </tr>
            <tr>
              <th>등급</th>
              <td>{props.rowData.a_user_grade}</td>
            </tr>
            <tr>
              <th>로그인수</th>
              <td>{props.rowData.a_login_cnt}</td>
            </tr>
            <tr>
              <th>마지막로그인</th>
              <td>{props.rowData.a_last_login_date}</td>
            </tr>
            <tr>
              <th>작성일</th>
              <td>{props.rowData.a_create_date}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewPopupContent;
