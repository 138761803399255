//import ReplyListWriteFrame from 'pages/home/basic/reply/frame/list_write/index';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from './xcolumn/list.js';
import Paging from 'components/common/list/paging/paging';
import { useSelector, useDispatch } from 'react-redux';
import DateFunc from 'lib/lyg/date_func';

function ReplyListWriteFrame(props) {
  const xColumnArr=getXcolumnJson();
  const user = useSelector((state) => state.user);
  const writeTextArea= useRef();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      ...{
        's_start_date':props.listOpt.s_start_date,
        's_end_date':'',
        's_par_id':props.listOpt.s_par_id,
        's_par_seq':props.listOpt.s_par_seq,
      }
    }
  });
  const write_init_data={
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_par_id":props.listOpt.s_par_id,
    "a_par_seq":props.listOpt.s_par_seq,
    "a_writer":user.user_name,
    "a_writer_seq":user.user_seq,
    "a_content":"",
  };
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [rowData,setRowData]= useState({...write_init_data});

  useEffect(() => {
    list();
  }, []);

  const handleInputChange=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/reply/reply/list',pageData.listOpt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
      }else{
        
      }
    });
  };

  const write = (inOptObj)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      "is_update":"",
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }

    let write_row={
      ...write_init_data,
      ...{
        "a_content":writeTextArea.current.value
      }
    };
    // if(!confirm("댓글을 등록 하시겠습니까?")){
    //   return false;
    // }
    writeTextArea.current.value="";
    setRowData({
      ...rowData,
      ...write_init_data
    });
    let write_form_data={
      "data_arr":[write_row],
      "is_udpate":"",
      "is_default_val":"1"
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/reply/reply/write',write_form_data)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        list();
      }else{
        
      }
    });
  };

  const goDelete = useCallback( inOptObj => {
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      "delRowData":{},
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }

    if(!confirm("삭제하시겠습니까?")){
      return false;
    }

    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/reply/reply/delete',{
      data_arr:[optObj["delRowData"]],
    },).then((response) => {
      if(response.data["result"]==="true"){
        list();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const replyListRows = infoArr.map((rowData,idx)=>{
    return (
      <div key={idx} className="reply-list-row">
        <div className="reply-row-title">
          {rowData.a_writer}
        </div>
        <div className="reply-row-content">
          {
            rowData.a_content && rowData.a_content.split('\n').map( (line,idx) => {
              return (<span key={idx}>{line}<br/></span>)
            })
          }
        </div>
        <div className="reply-row-bottom">
          {rowData.a_create_date}
        </div>
        {(user.user_grade=="master"||user.user_seq==rowData.a_writer_seq)&&
          <button className="btn-s btn-red" onClick={()=>{goDelete({"delRowData":rowData});}}>삭제</button>
        }
      </div>
    );
  });

  return (
    <div className="reply-wrap">
      <h1 className="con-p-gray">리플 ({countInfo["tot"]} 건) </h1>
      <div className="reply-list-wrap">
        {replyListRows}
      </div>
      <div className="reply-write-wrap">
        <div className="con-span-black">
           {user.user_name}
        </div>
        <textarea className="reply-write-content" name="a_content" onChange={handleInputChange} ref={writeTextArea} ></textarea>
        <div className="text-right">
          <button className="btn btn-blue" onClick={()=>{write();}} >등록</button>
        </div>
      </div>
      <div>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
      </div>
    </div>
  );
}
ReplyListWriteFrame.defaultProps = {
  "listOpt":{
    "s_start_date":"",
    's_par_id':"",
    's_par_seq':"",
  }
}
export default ReplyListWriteFrame;