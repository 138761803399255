export const hmenu={
  "basic":{"name":"기본","url":"/home/user/list","msg":""},// /
  "project":{"name":"프로젝트","url":"/home/work/list","msg":""},///home/project
  "report":{"name":"업무일지","url":"/home/report/list","msg":""},
  "community":{"name":"커뮤니티","url":"/home/board/list","msg":""},
};
export const smenu={
  "home":{"name":"홈","hmenu":"","url":"/","is_show":""},
  
  "corp_list":{"name":"법인관리","hmenu":"basic","url":"/home/corp/list","is_show":"1"},
  "user_list":{"name":"회원관리","hmenu":"basic","url":"/home/user/list","is_show":"1"},
  "authg_list":{"name":"권한그룹","hmenu":"basic","url":"/home/authg/list","is_show":"1"},
  "user_auth_list":{"name":"회원권한","hmenu":"basic","url":"/home/user_auth/list","is_show":"1"},
  "sort_list":{"name":"구분관리","hmenu":"basic","url":"/home/sort/list","is_show":"1"},
  "file_list":{"name":"파일관리","hmenu":"basic","url":"/home/file/list","is_show":"1"},
  "list_opt_list":{"name":"리스트설정","hmenu":"basic","url":"/home/list_opt/list","is_show":"1"},
  "reply_list":{"name":"리플관리","hmenu":"basic","url":"/home/reply/list","is_show":"1"},
  "log_login_list":{"name":"로그인로그","hmenu":"basic","url":"/home/login_log/list","is_show":"1"},
  
  "mypage":{"name":"마이페이지","hmenu":"basic","url":"/home/mypage","is_show":""},
  
  "report_list":{"name":"업무일지","hmenu":"report","url":"/home/report/list","is_show":"1"},

  "project_main":{"name":"프로젝트 메인","hmenu":"project","url":"/home/project","is_show":""},
  "project_list":{"name":"프로젝트","hmenu":"project","url":"/home/project/list","is_show":"1"},
  "work_list":{"name":"작업내역","hmenu":"project","url":"/home/work/list","is_show":"1"},
  "conference_list":{"name":"회의록","hmenu":"project","url":"/home/conference/list","is_show":"1"},
  "plan_list":{"name":"설계자료","hmenu":"project","url":"/home/plan/list","is_show":"1"},

  "community_main":{"name":"커뮤니티 메인","hmenu":"community","url":"/home/community","is_show":""},
  "board_list":{"name":"공지사항","hmenu":"community","url":"/home/board/list","is_show":"1"},
};