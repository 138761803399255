import { Link,useNavigate } from 'react-router-dom';
import { useEffect,useState,useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDark } from 'store/dark/dark';
import {removeUser,setUser } from 'store/user/user';
import TopMenu from 'components/home/menu/menu';
import SubMenu from 'components/home/menu/sub_menu';
import prj_img from 'img/ico/plani_logo.png';
import getSortFunc from 'components/common/xcolumn/get_data/get_sort';
import getProjectFunc from 'components/common/xcolumn/get_data/get_project';
import getUserFunc from 'components/common/xcolumn/get_data/get_user';
import checkVersion from './version/check_version';
import getXcolumnFunc from 'components/common/xcolumn/get_data/get_xcolumn';
import ChatPopup from './chat/chat_popup';
import UnreadTotSpan from './unread_tot/unread_tot';

function HomeHeader(props){
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [isShowMenu,setIsShowMenu] = useState(false);
  const chatPopupRef=useRef();

  useEffect(() => {
    checkVersion();
    dispatch(setDark(localStorage.theme));
    setDarkPage(localStorage.theme);
    checkLogin();
    getSortFunc();
    getProjectFunc();
    getUserFunc();
    getXcolumnFunc({"user_seq":user.user_seq});
  },[]);
  
  const goLogOut=()=>{
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/login/logout',{ 
      token_id: localStorage.lyg_mproject_token_id,
    },).then((response) => {
      
    });
    dispatch(removeUser());
    localStorage.removeItem("lyg_mproject_token_id");
    navigate("/login");
  };

  const checkLogin=()=>{
    if(localStorage.lyg_mproject_token_id&&user.user_seq===""){
      window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/login/check_login',{ 
        token_id: localStorage.lyg_mproject_token_id,
      },).then((response) => {
        if(response.data["result"]==="true"){
          var client_info=response.data.data["client_info"];
          dispatch(setUser(client_info));
          navigate("/");
        }else{
          goLoginPageByUrl();
        }
      });
    }
    if(localStorage.lyg_mproject_token_id){
      
    }else{
      goLoginPageByUrl();
    }
  };

  const goLoginPageByUrl=()=>{
    navigate("/login");
  };

  const goMenuDisplayToggle=()=>{
    let items=document.getElementsByClassName("header-item-hidden");
    setIsShowMenu(!isShowMenu);
    if(isShowMenu){
      for(let i=0;i<items.length;i++){
        items[i].classList.remove("active");
      }
    }else{
      for(let i=0;i<items.length;i++){
        if(i!=0){
          items[i].classList.add("active");
        }
      }
    }
  };

  const darkModeClick=()=>{
    if (localStorage.theme === 'dark' ) {
      setDarkPage("light");
    }else{
      setDarkPage("dark");
    }
  }
  const setDarkPage=(sort)=>{
    if (sort === 'dark' ) {
      document.getElementById("main_wrap").classList.add('dark');
      document.getElementById("modal").classList.add('dark');
      document.body.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    }else{
      document.getElementById("main_wrap").classList.remove('dark');
      document.getElementById("modal").classList.remove('dark');
      document.body.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
    dispatch(setDark(localStorage.theme));
  };

  return (
    <div className="header-wrap">
      <div className="header-wrap-top-wrap">
        <div className="header-wrap-top">
          <div className="header-item header-item-hidden header-item-first con-p-gray">
            <Link to="/" ><img className="h-11" src={prj_img} alt="프로젝트" /></Link>
          </div>
          <TopMenu></TopMenu>
          <div className="header-item header-item-hidden">
            <div className="header-item-right-login">
              <a href="#" className="text-sm px-1" onClick={()=>{chatPopupRef.current.openOnePopup();}} >
                톡
                <UnreadTotSpan></UnreadTotSpan>
              </a> |
              <Link className="text-sm px-1" to="/home/mypage" ><span className="underline underline-offset-2">{user.user_name}</span> 님</Link> |
              <a href="#" className="text-sm px-1" onClick={goLogOut} >로그아웃</a> |
              <a href="#" className="text-sm px-1" onClick={darkModeClick} >다크</a>
            </div>
          </div>
          <a href="#" className="header-menu-img" onClick={goMenuDisplayToggle}>
            <svg viewBox="0 0 100 80" width="30" height="30">
              <rect width="100" height="15"></rect>
              <rect y="30" width="100" height="15"></rect>
              <rect y="60" width="100" height="15"></rect>
            </svg>
          </a>
        </div>
      </div>
      <SubMenu></SubMenu>
      <ChatPopup ref={chatPopupRef}></ChatPopup>
    </div>
  );
}

export default HomeHeader;
