import HoemHeader from 'components/home/header/header';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paging from 'components/common/list/paging/paging';
import ListAggrid from 'components/common/list/aggrid/list';
import SearchPeriodArea from 'components/common/list/search/search_period';
import SearchUserSelect from 'components/common/list/search/search_user';
import getXcolumnJson from '../xcolumn/list.js';

function WorkList() {
  const xColumnArr=getXcolumnJson();
  const user = useSelector((state) => state.user);
  const listAggridRef= useRef();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  useEffect(() => {
    //list();
  }, []);

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/log_login/log_login/list',pageData.listOpt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( e => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/log_login/log_login/delete',{
      data_arr:selected_row,
    },).then((response) => {
      if(response.data["result"]==="true"){
        list();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  return (
    <div>
      <HoemHeader></HoemHeader>
      <div className="main-content-wrap">
        <div className="main-content-div">
          <SearchPeriodArea
            xColumnArr={xColumnArr}
            listOpt={pageData.listOpt}
            list={list}
          >
            <SearchUserSelect s_user_seq={pageData.listOpt.s_user_seq} 
              onChange={(e)=>{list({"now_page":1,"s_user_seq":e.target.value});}}
              table={xColumnArr.table} ></SearchUserSelect>
          </SearchPeriodArea>
          <div className="btn-box-left">
            <button className="btn btn-round-orange" onClick={goDelete}>삭제</button>
            <button className="btn btn-blue" onClick={()=>{list({"now_page":1});}} >검색</button>
          </div>
          <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{...xColumnArr}}
            list={list}
            gridOpt={{
              fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
              is_idx_num:false,
              is_add_checkbox:true,
              floatingFilter:true,
              onGridReady:()=>{
                list();
              },
            }}
          ></ListAggrid>
          <div>
            <Paging now_page={pageData.listOpt.now_page}
              num_per_page={pageData.listOpt.num_per_page}
              total_rec={countInfo.tot} 
              onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkList;
