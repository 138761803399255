import TopMenu from 'components/common/test/menu/top_menu';
import SubMenu from '../menu.js';

function DesignButton() {
  return (
    <div>
      <TopMenu></TopMenu>
      <SubMenu></SubMenu>
      <h2 className="text-2xl text-gray-900 dark:text-gray-200" >버튼</h2>
      
      <div className="btn-box-center">
        <button className="btn-l btn-dark">다크</button>
        <button className="btn-l btn-dark2">다크2</button>
        <button className="btn-l btn-gray">그레이</button>
        <button className="btn-l btn-brown">브라운</button>
        <button className="btn-l btn-line-gray">그레이아웃</button>
      </div>
      <div className="btn-box-center mt-10">
          <button className="btn-l btn-round-green">그린</button>
          <button className="btn-l btn-round-yellow">옐로우</button>
          <button className="btn-l btn-round-dark">다크</button>
          <button className="btn-l btn-round-green2">연두</button>
          <button className="btn-l btn-round-orange">오렌지</button>
        </div>
    </div>
  );
}

export default DesignButton;
