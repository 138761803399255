import XcolumnFunc from "components/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();
import DateFunc from 'lib/lyg/date_func';

const getXcolumnJson=()=>{
  let initData={
    table:"prj_work",
    list_id:"work_list",
    create_user_col:"a_worker_seq",
    x_column_list_arr:[
      {"key":"a_project_seq","name":"프로젝트","width":"130","is_show":"1"},
      {"key":"a_title","name":"제목","width":"150","is_show":"1"},
      {"key":"a_request_date","name":"요청일","width":"110","is_show":"1"},
      {"key":"a_worker","name":"작업자","width":"110","is_show":"1"},
      {"key":"a_requester","name":"요청자","width":"110","is_show":"1"},
      {"key":"a_start_date","name":"시작일","width":"110","is_show":"1"},
      {"key":"a_end_date","name":"종료일","width":"110","is_show":"1"},
      {"key":"a_sort","name":"구분","width":"110","is_show":"1"},
      {"key":"a_state","name":"상태","width":"110","is_show":"1"},
      {"key":"a_create_date","name":"생성일","width":"180","is_show":"1"},
      {"key":"a_update_date","name":"수정일","width":"110","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_request_date DESC,a_create_date DESC',
      'order_type':'',
      's_date_type':'a_request_date',
      's_start_date':"",//DateFunc.get_date_format(new Date(),"Y-01-01")
      's_end_date':'',
      's_worker_seq':'',
      'sc':{},
    },
    order_id_col_arr:[
      {"value":"a_request_date","text":"요청일"},
      {"value":"a_create_date","text":"작성일"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;