import TopMenu from 'components/common/test/menu/top_menu';
import SubMenu from '../menu.js';
import { useState } from 'react';
import LayerPopup from 'components/common/popup/layer/layer_popup';
import WritePopupContent from './popup/write_content';

function DesignPopup() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const goPopupToggle=()=>{
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div>
      <TopMenu></TopMenu>
      <SubMenu></SubMenu>
      <h2 className="con-title" >팝업</h2>
      <div>
        <p className="con-p-gray">팝업섭</p>
        <div className="btn-box-center">
          <button className="btn btn-blue" onClick={()=>{goPopupToggle();}}>팝업 토글</button>
        </div>
      </div>
      {isPopupOpen && 
        <LayerPopup closePopup={()=>{setIsPopupOpen(false);}} title="팝업" >
          <WritePopupContent userName="ㅋㅋㅋㅋㅋ" closePopup={()=>{setIsPopupOpen(false);}}  ></WritePopupContent>
        </LayerPopup>
      }
    </div>
  );
}

export default DesignPopup;
