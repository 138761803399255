import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from 'lib/lyg/date_func';
import strFunc from 'lib/lyg/string';
import {customHeaderFunc} from 'components/common/date_picker/custom_header';
import {CustomInputWrite} from 'components/common/date_picker/custom_input';
import getXcolumnJson from '../../xcolumn/list.js';
import FileListWriteFrame from 'pages/home/basic/file/frame/write/index';
import WriteInputFrame from 'pages/home/basic/file/frame/write_input/index';

function WritePopupContent(props) {
  const xColumnArr=getXcolumnJson();
  xColumnArr.select_arr["a_project_seq"]=xColumnArr.func.getProjectArr();
  xColumnArr.select_arr["a_project_seq"].unshift({"value":"","text":"선택없음"});
  
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const fileWriteOneRef = useRef();
  const [rowData,setRowData]=useState({
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_project_seq":"",
    "a_public_sort":"",
    "a_sort":"",
    "a_title":"",
    "a_content":"",
    "a_request_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_requester":"",
    "a_worker":user.user_name,
    "a_worker_seq":user.user_seq,
    "a_start_date":"",
    "a_end_date":"",
    "a_state":"ready",
  });

  useEffect(()=>{
    if(props.isUpdate){
      let changeRowData={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };
  const onChangeDatePicker=(key,date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    let change_data={
      [key]:date_str
    };
    if(key=="a_date"&&props.isUpdate!=true){
      change_data["a_ymd"]=strFunc.str_replace("-","",date_str);
    }
    setRowData({
      ...rowData,
      ...change_data
    });
  };

  const goWrite=useCallback( e => {
    if(rowData.a_title==""){
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if(rowData.a_request_date==""){
      alert("요청일 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let formJsonData={
      "data_arr":[rowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(props.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/project/work/work/write',formJsonData)
    .then((response) => {
      if(response.data["result"]==="true"){
        if(fileWriteOneRef.current==null){
          props.callback(response.data["data"]);
          props.closePopup();
        }else{
          let childFileInput=fileWriteOneRef.current.getFileInput();
          if(childFileInput.current.value==""){
            props.callback(response.data["data"]);
            props.closePopup();
          }else{
            let priVal=xColumnArr.func.getPriValByDataRow({
              xColumnArr:xColumnArr,
              rowData:response.data["data"][0]
            });
            fileWriteOneRef.current.goWrite({
              "a_par_seq":priVal
            });
          }
        }
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);
  
  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>프로젝트</th>
              <td>
                <select className="row-input" name="a_project_seq" value={rowData.a_project_seq} onChange={handleInputChange}  >
                  {xColumnArr["select_arr"]["a_project_seq"].map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>상태</th>
              <td>
                <select className="row-input" name="a_state" value={rowData.a_state} onChange={handleInputChange}  >
                    <option value="ready" >준비</option>
                    <option value="ing" >작업중</option>
                    <option value="complete" >완료</option>
                    <option value="stop" >중지</option>
                    <option value="fail" >실패</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>구분</th>
              <td>
               <input type="text" className="row-input" name="a_sort" value={rowData.a_sort} onChange={handleInputChange} placeholder="구분" />
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td>
               <input type="text" className="row-input" name="a_title" value={rowData.a_title} onChange={handleInputChange} placeholder="제목" />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p className="con-p-gray">작업내용</p>
                <textarea className="row-input h-44" name="a_content" value={rowData.a_content} onChange={handleInputChange} placeholder="작업내용" ></textarea>
              </td>
            </tr>
            <tr>
              <th>요청일</th>
              <td>
                <DatePicker 
                  selected={rowData.a_request_date!=""?new Date(rowData.a_request_date):null} 
                  onChange={(date) => {
                    onChangeDatePicker("a_request_date",date);
                  }}
                  locale={ko} 
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
            <tr>
              <th>요청자</th>
              <td>
                <input type="text" className="row-input" name="a_requester" value={rowData.a_requester} onChange={handleInputChange} placeholder="요청자" />
              </td>
            </tr>
            <tr>
              <th>작업자</th>
              <td>
                <input type="text" className="row-input" name="a_worker" value={rowData.a_worker} onChange={handleInputChange} placeholder="작업자" />
              </td>
            </tr>
            <tr>
              <th>시작일</th>
              <td>
                <DatePicker 
                  selected={rowData.a_start_date!=""?new Date(rowData.a_start_date):null} 
                  onChange={(date) => {
                    onChangeDatePicker("a_start_date",date);
                  }}
                  locale={ko} 
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
            <tr>
              <th>종료일</th>
              <td>
                <DatePicker 
                  selected={rowData.a_end_date!=""?new Date(rowData.a_end_date):null} 
                  onChange={(date) => {
                    onChangeDatePicker("a_end_date",date);
                  }}
                  locale={ko} 
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {
        rowData.a_seq=="" && 
        <WriteInputFrame
        ref={fileWriteOneRef}
        init_data={{
          a_par_id:xColumnArr.table,
          a_par_seq:props.rowData.a_ymd+","+props.rowData.a_seq,
        }}
        callback={()=>{props.callback();props.closePopup();}}
        ></WriteInputFrame>
      }
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
      {
        rowData.a_seq!="" && 
        <FileListWriteFrame
          listOpt={
            {
              s_par_id:xColumnArr.table,
              s_par_seq:props.rowData.a_ymd+","+props.rowData.a_seq,
              s_start_date:rowData.a_date
            }
          }
        ></FileListWriteFrame>
      }
    </div>
  );
}

export default WritePopupContent;
