import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addMsgInfoArr,removeMsgInfoArr,setMemNowArr,removeMemNowArr,setUnreadTot } from 'store/chat/chat';
import PopMenu from 'pages/home/chat/x_include/pop_menu/menu';
import DateFunc from 'lib/lyg/date_func';
import getChatRows from './chat_row/chat_row';
import './chat.css';
import io from 'socket.io-client';
import LayerPopup from 'components/common/popup/layer/layer_popup';
import FindUserList from 'pages/home/chat/chat/popup/find_user/index';
import ChatMemList from './mem_list/mem_list';

function ListPage(props) {
  const dispatch=useDispatch();
  const user = useSelector((state) => state.user);
  const chat = useSelector((state) => state.chat);
  const [socket, setSocket] = useState();
  const [chatMsg, setChatMsg] = useState("");
  const sendMsgInput = useRef();
  const chatContentDivRef=useRef();
  const [memInfoArr,setMemInfoArr]=useState([]);
  const [isMemShow,setIsMemShow]=useState(false);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"find",//view
    s_except_user_seq:[user.user_seq],
    title:"팝업",
    width:"500px",
    height:"70%",
  });

  useEffect(()=>{
    sendMsgInput.current.focus();

    const socketIo = io(process.env.REACT_APP_Chat_SERVER_URL,{
      cors: {
        origin: "*",
      }
    });
    dispatch(removeMsgInfoArr());
    dispatch(setUnreadTot({"time":""}));
    
    socketIo.emit("set_user_data",{
      "room_seq":props.roomInfo["a_seq"],
      "user_name":user.user_name,
      "user_seq":user.user_seq,
    });
    
    socketIo.on('greet', (data) => {
      //console.log(data);
    });
    socketIo.on('chat_msg', (data) => {
      dispatch(addMsgInfoArr([data]));
      setTimeout(function(){
        chatContentDivRef.current.scrollTop=chatContentDivRef.current.scrollHeight;
      },100);
    });

    socketIo.emit("get_room_users",{
      "room_seq":props.roomInfo["a_seq"],
    });
    socketIo.on('get_room_users', (data) => {
      dispatch(setMemNowArr(data));
    });

    setSocket(socketIo);

    list_member();
    list_message();

    //현재접속자파악 반복
    const getNowUserLoop=setInterval(()=>{
      if(socketIo){
        socketIo.emit("get_room_users",{
          "room_seq":props.roomInfo["a_seq"],
        });
      }
    },5000);
    //끝날 땐 중지
    return () => {
      clearInterval(getNowUserLoop);
    };
  },[]);

  useEffect(() => {
    return () => {
      if(socket) { socket.disconnect(); }
    };
  }, [socket]);

  const list_member = ()=>{
    let mem_list_opt={
      "s_room_seq":props.roomInfo["a_seq"],
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/chat/member/member/list',mem_list_opt)
    .then((response) => {
      if(response.data["result"]==="true"){
        setMemInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };
  const list_message = ()=>{
    let mem_list_opt={
      "s_room_seq":props.roomInfo["a_seq"],
      "is_check_unread":"1",
      "check_unread_room_seq":props.roomInfo["a_seq"],
      "check_unread_user_seq":user.user_seq,
      "s_start_date":props.roomInfo["a_create_date"].substring(0,10),
      "order_id":"a_create_date DESC",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
      "max_limit_num":"1000",
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/chat/chat/chat/list',mem_list_opt)
    .then((response) => {
      if(response.data["result"]==="true"){
        dispatch(addMsgInfoArr(
          response.data["data"]["info_arr"].reverse()
        ));
        setTimeout(function(){
          chatContentDivRef.current.scrollTop=chatContentDivRef.current.scrollHeight;
        },100);
      }else{
        
      }
    });
  };

  const sendChatMessage=()=>{
    let add_chat_row={
      "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
      "a_seq":"",
      "a_room_seq":props.roomInfo["a_seq"],
      "a_writer":user.user_name,
      "a_writer_seq":user.user_seq,
      "a_content":chatMsg,
    };
    let now_user_seq_arr=[];
    for(let i=0;i<chat.memNowArr.length;i++){
      now_user_seq_arr.push(chat.memNowArr[i].user_seq);
    }
    let chat_w_opt={
      "chat_row":add_chat_row,
      "now_user_seq_arr":now_user_seq_arr
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/chat/chat/chat/add_chat',chat_w_opt)
    .then((response) => {
      if(response.data["result"]==="true"){
        socket.emit("chat_msg",response.data["data"][0]);
      }else{
        
      }
    });
    setChatMsg("");
    sendMsgInput.current.focus();
  };

  const outChatRoom=()=>{
    if(!confirm("방에서 나가시겠습니까?")){
      return false;
    }
    let out_room_opt={
      "room_seq":props.roomInfo["a_seq"],
      "user_seq":user.user_seq,
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/chat/room/room/out_room',out_room_opt)
    .then((response) => {
      if(response.data["result"]==="true"){
        props.closePopup();
        props.openOnePopup({
          "sort":"room",
          "roomInfo":null,
        });
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const openOnePopup=(sort)=>{
    if(sort==undefined){sort="find";}
    let pop_title="회원초대";
    let s_except_user_seq=[];
    for(let i=0;i<memInfoArr.length;i++){
      s_except_user_seq.push(memInfoArr[i]["a_user_seq"]);
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "title":pop_title,
      "sort":sort,
      "s_except_user_seq":s_except_user_seq,
    });
  };

  const addUserCallback=(user_arr)=>{
    if(user_arr.length==0){
      return false;
    }
    //유저등록
    let add_mem_row_arr=[];
    for(let i=0;i<user_arr.length;i++){
      let tmp_u_info=user_arr[i];
      add_mem_row_arr.push({
        "a_room_seq":props.roomInfo["a_seq"],
        "a_user_seq":tmp_u_info["a_seq"],
        "a_user_name":tmp_u_info["a_user_name"],
        "a_is_owner":"",
      });
    }
    let add_mem_opt={
      "data_arr":add_mem_row_arr,
      "is_default_val":"1"
    };
    window.axios.post( process.env.REACT_APP_API_URL+'/api/chat/member/member/write',add_mem_opt)
    .then((response) => {
      if(response.data["result"]==="true"){
        list_member();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <div className="chat_wrap">
      <div className="chat_div">
        <div className="chat_top_menu_div">
          <PopMenu
            sort="chat"
            closePopup={props.closePopup}
            openOnePopup={props.openOnePopup}
          ></PopMenu>
          <button className="btn btn-line-gray connector_show_btn" onClick={()=>{setIsMemShow(!isMemShow)}}>접속자</button>
        </div>
        <div className="chat_content_div" ref={chatContentDivRef}>

          {
            getChatRows({
              "msgInfoArr":chat.msgInfoArr,
              "user_seq":user.user_seq,
            })
          }

        </div>
        <div className="chat_send_div">
          <div className="chat_send_plus">
            <button className='btn btn-gray' style={{"height":"95%"}}>+</button>
          </div>
          <div className="chat_send_msg_div">
            <textarea className="chat_send_msg_input" value={chatMsg} 
              onChange={(e)=>{setChatMsg(e.target.value)}} 
              onKeyUp={(e)=>{if (e.key === 'Enter') {sendChatMessage();}}}
              ref={sendMsgInput} ></textarea>
          </div>
          <div className="chat_send_btn_div">
            <button className='btn btn-gray' onClick={sendChatMessage} style={{"height":"95%"}}>전송</button>
          </div>
        </div>
      </div>
      {isMemShow &&
      <ChatMemList
        memInfoArr={memInfoArr}
        setIsMemShow={setIsMemShow}
        outChatRoom={outChatRoom}
        openOnePopup={openOnePopup}
      ></ChatMemList>
      }
      {popupData.isOpen && 
        <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
          width={popupData.width} height={popupData.height} >
          {popupData.sort==="find"&&
            <FindUserList callback={(data)=>{addUserCallback(data);}} s_except_user_seq={popupData.s_except_user_seq}
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></FindUserList>
          }
        </LayerPopup>
      }
    </div>
  );
}
ListPage.defaultProps = {
  "closePopup":()=>{},
  "roomInfo":null,
  "openOnePopup":()=>{},
}
export default ListPage;
