import { useEffect } from 'react';
import HoemHeader from 'components/home/header/header';

function ProjectMain() {
  useEffect(() => {
    
  }, []);

  return (
    <div>
      <HoemHeader></HoemHeader>
      <div className="main-content-wrap">
        <div className="main-content-div">
          <h1 className="con-p-gray">프로젝트 메인</h1>
        </div>
      </div>
    </div>
  );
}

export default ProjectMain;
