import HoemHeader from 'components/home/header/header';
import bread_img from 'img/test/bread.jpg';
import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <div>
      <HoemHeader></HoemHeader>
      <div className="main-content-wrap">
        <div className="main-content-div">
          <div className="btn-box-center con-title">
            메인페이지
          </div>
          <div className="btn-box-center">
            {/* <Link className="btn btn-dark" to="/test/main">테스트 페이지로</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
