import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from 'store/user/user';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import DateFunc from 'lib/lyg/date_func';

function WritePopupContent(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const fileInput = useRef();
  const writeForm = useRef();

  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const [rowData,setRowData]=useState({
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_par_id":props.init_data.a_par_id,
    "a_par_seq":props.init_data.a_par_seq,
    "a_sort1":props.init_data.a_sort1,
    "a_sort2":props.init_data.a_sort2,
    "a_sort3":props.init_data.a_sort3,
    "a_writer":user.user_name,
    "a_create_user":user.user_seq,
  });

  useEffect(()=>{
    if(props.isUpdate){
      let changeRowData={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const goWrite=useCallback( e => {
    if(fileInput.current.value==""){
      alert("파일 선택이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    var form = writeForm.current;
    var form_json_data= new FormData(form);
    form_json_data.append("data_arr",JSON.stringify([rowData]));
    form_json_data.append("authorization",localStorage.lyg_mproject_token_id);
    
    window.axios.post( process.env.REACT_APP_FILE_SERVER_URL+'/api/comp/file/file/receive',form_json_data)
    .then((response) => {
      if(response.data["result"]==="true"){
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  return (
    <div>
      <form id="write_form" method="post" ref={writeForm} >
        <div className="write-table-div mt-1">
          <table>
            <colgroup>
              <col width="130px"></col>
              <col width="*"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>설명</th>
                <td>
                  <input type="text" className="row-input" name="a_sort3" value={rowData.a_sort3||""} onChange={handleInputChange} placeholder="설명" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <input type="file" id="files" className="row-input" name="input_file[]" ref={fileInput} />
      </form>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

WritePopupContent.defaultProps = {
  "isUpdate":false,
  "init_data":{
    "a_par_id":"",
    "a_par_seq":"",
    "a_sort1":"",
    "a_sort2":"",
    "a_sort3":"",
  },
  "rowData":{}
}

export default WritePopupContent;
