import TestMain from 'pages/test/main';
import DesignMain from 'pages/test/design/main';
import DesignButton from 'pages/test/design/button/button';
import DesignLayout from 'pages/test/design/layout/layout';
import DesignWrite from 'pages/test/design/write/write';
import DesignList from 'pages/test/design/list/list';
import FileList from 'pages/test/design/file_list/index';
import DesignPopup from 'pages/test/design/popup/popup';

import ExMain from 'pages/test/external/main';
import Chat1 from 'pages/test/external/chat/chat';
import DBPage from 'pages/test/external/db/db';

const testPages=[
  {
    path: "/test/main",
    element: <TestMain></TestMain>,
  },
  {
    path: "/test/design",
    element: <DesignMain></DesignMain>,
  },
  {
    path: "/test/design/button",
    element: <DesignButton></DesignButton>,
  },
  {
    path: "/test/design/layout",
    element: <DesignLayout></DesignLayout>,
  },
  {
    path: "/test/design/write",
    element: <DesignWrite></DesignWrite>,
  },
  {
    path: "/test/design/list",
    element: <DesignList></DesignList>,
  },
  {
    path: "/test/design/popup",
    element: <DesignPopup></DesignPopup>,
  },
  {
    path: "/test/file/list",
    element: <FileList></FileList>,
  },

  {
    path: "/test/ex",
    element: <ExMain></ExMain>,
  },
  {
    path: "/test/ex/chat1",
    element: <Chat1></Chat1>,
  },
  {
    path: "/test/ex/db",
    element: <DBPage></DBPage>,
  },
];

export default testPages;