//import LayerPopupPortal from 'components/common/popup/layer/layer_popup_portal';
/*
{isPopupOpen && 
  <LayerPopupPortal closePopup={()=>{setIsPopupOpen(false);}} title="팝업" >
    <WritePopupContent userName="ㅋㅋㅋㅋㅋ" closePopup={()=>{setIsPopupOpen(false);}}  ></WritePopupContent>
  </LayerPopupPortal>
}
*/
import { createPortal } from "react-dom";
import { useEffect, useRef } from 'react';

function LayerPopupPortal(props) {

  const popupBackDiv=useRef();
  useEffect(() => {
    popupBackDiv.current.addEventListener("click", (e)=>{
      if(e.target.classList.contains('popup-background')){
        //props.closePopup();
      }
    }, false);
  },[]);

  let popup_wrap_style={
    width:props.width,
    height:props.height
  };

  return createPortal(
    <div className="popup-background" ref={popupBackDiv} >
      <div className="popup-wrap" style={popup_wrap_style}>
        <div className="popup-title-bar">
          <h2 className="con-title" >{props.title}</h2>
          <button className="popup-title-close-btn" onClick={()=>{props.closePopup();}}>X</button>
        </div>
        <div className="popup-content-div">
          {props.children}
        </div>
      </div>
    </div>
  ,document.getElementById("modal"));
}

LayerPopupPortal.defaultProps = {
  closePopup:()=>{},
  callBack:()=>{},
  title:'',
  width:'300px',
  height:'300px',
};

export default LayerPopupPortal;
