import XcolumnFunc from "components/common/xcolumn/xcolumn/xcolumn_func";

const xColumnArr=XcolumnFunc.getDefaultXcolumn();

xColumnArr.table="sortd";
xColumnArr.x_column_list_arr=[
  {"key":"a_value","name":"내용","width":"110","is_show":"1"},
  {"key":"a_text","name":"설명","width":"100","is_show":"1"},
  {"key":"a_order_num","name":"순번","width":"100","is_show":"1"},
];
xColumnArr.x_column_list_orig_arr=[...xColumnArr.x_column_list_arr];
xColumnArr.pri_col_arr=["a_par_id","a_par_col","a_seq"];

xColumnArr.select_arr=XcolumnFunc.getSortByTable({"table":xColumnArr["table"]});

export default xColumnArr;