import XcolumnFunc from "components/common/xcolumn/xcolumn/xcolumn_func";

const xColumnArr=XcolumnFunc.getDefaultXcolumn();

xColumnArr.table="list_opt";
xColumnArr.list_id="list_opt_list";
xColumnArr.x_column_list_arr=[
  {"key":"key","name":"키명","width":"100","is_show":""},
  {"key":"name","name":"이름","width":"140","is_show":"1"},
  {"key":"width","name":"크기","width":"120","is_show":"1"},
  {"key":"is_show","name":"보임","width":"100","is_show":"1"},
];
xColumnArr.x_column_list_orig_arr=XcolumnFunc.getDefaultXcolumn(xColumnArr.x_column_list_arr);

xColumnArr.select_arr=XcolumnFunc.getSortByTable({"table":xColumnArr["table"]});
xColumnArr.select_arr["is_show"]=[
  {"value":"","text":"아니오"},
  {"value":"1","text":"예"},
];

export default xColumnArr;