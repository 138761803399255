import { forwardRef,useImperativeHandle,useState, useRef, useEffect, useMemo, useCallback } from 'react';
import LayerPopupPortal from 'components/common/popup/layer/layer_popup_portal';
import FindUserList from 'pages/home/chat/user/popup/find/index';
import RoomList from 'pages/home/chat/room/popup/list/index';
import ChatList from 'pages/home/chat/chat/popup/list/index';

const ChatPopup=forwardRef((props, ref) => {
  const [popupData,setPopupData] = useState({
    isOpen:false,
    title:"팝업",
    width:"800px",
    height:"80%",
    sort:"user",
    roomInfo:null,
  });

  const makeRoomByUser=(userArr)=>{
    let formJsonData={
      "user_data_arr":userArr,
    };
    
    window.axios.post( process.env.REACT_APP_API_URL+'/api/chat/room/room/add_room',formJsonData)
    .then((response) => {
      if(response.data["result"]==="true"){
        openOnePopup({
          "sort":"chat",
          "roomInfo":response.data["data"]["room_info"],
          "title":response.data["data"]["room_info"]["a_name"]
        });
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const openOnePopup=(inOptObj)=>{
    if(inOptObj==undefined){inOptObj={};}
    let tmpPopupData={...popupData};
    for(let key in tmpPopupData){
      if(inOptObj[key]!=undefined){
        tmpPopupData[key]=inOptObj[key];
      }
    }
    let pop_title="유저찾기";
    if(tmpPopupData.sort=="room"){
      pop_title="채팅방";
    }else if(tmpPopupData.sort=="chat"){
      pop_title="채팅";
      if(tmpPopupData.roomInfo==null){
        alert("방정보 없음.");
        return false;
      }
      if(inOptObj.title!=undefined){
        pop_title=inOptObj.title;
      }
    }
    tmpPopupData.isOpen=true;
    tmpPopupData.title=pop_title;
    setPopupData(tmpPopupData);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    openOnePopup
  }));

  return (
    <>
      {popupData.isOpen && 
        <LayerPopupPortal closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
          width={popupData.width} height={popupData.height}>
          
          { (popupData.sort=="user")&&
            <FindUserList 
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}  
              callBack={(data)=>{makeRoomByUser(data["infoArr"]);}}
              openOnePopup={openOnePopup}
            ></FindUserList>
          }

          { (popupData.sort=="room")&&
            <RoomList 
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}  
              openOnePopup={openOnePopup}
            ></RoomList>
          }

          { (popupData.sort=="chat")&&
            <ChatList 
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
              roomInfo={popupData.roomInfo} 
              openOnePopup={openOnePopup}
            ></ChatList>
          }

        </LayerPopupPortal>
      }
    </>
  );
});

ChatPopup.defaultProps = {
  
}

export default ChatPopup;
