
import { useState,useEffect } from 'react';
import strFunc from 'lib/lyg/string';

function SearchArea(props) {
  let [searchData,setSearchData]=useState(
    {
      's_grade':[],
      's_type':[],
      's_id':[],
      's_crud':[],
    }
  );
  useEffect(() => {
    setSearchData({...props.pageData.listOpt});
  }, []);
  
  const goSearch=(key,val)=>{
    let tmpSearchData={
      ...searchData,
    };
    let tmpRowArr=[];
    let tmpIsHasVal=false;
    let tmpAllowMultiKeyArr=[];
    let tmpIsAllowMulti=false;
    if(strFunc.str_in_array(key,tmpAllowMultiKeyArr)!=-1){
      tmpIsAllowMulti=true;
    }
    for(let i=0;i<tmpSearchData[key].length;i++){
      //이미있는지확인
      if(tmpSearchData[key][i]==val){
        tmpIsHasVal=true;
      }else{
        //중복허요일때 등록
        if(tmpIsAllowMulti){
          tmpRowArr.push(tmpSearchData[key][i]);
        }
      }
    }
    //없으면등록
    if(tmpIsHasVal==false){
      tmpRowArr.push(val);
    }
    
    tmpSearchData[key]=tmpRowArr;
    setSearchData(tmpSearchData);

    tmpSearchData["now_page"]="1";
    props.list(tmpSearchData);
  };

  const getSearchItemArr=(arr,s_key)=>{
    let itemArr=arr.map((col_data,idx)=>{
      let selectClassName="";
      if(strFunc.str_in_array(col_data["value"],searchData[s_key])!=-1){
        selectClassName="font-bold text-cyan-300 dark:text-cyan-600";
      }
      return (
        <label key={idx} onClick={(e)=>{goSearch(s_key,col_data["value"]);}} className="p-1 pl-2 whitespace-nowrap">
          <a href="#" className={selectClassName}>{col_data["text"]}</a>
        </label>
      );
    });
    return itemArr;
  };
  const gradeArr=getSearchItemArr(props.xColumnArr.authSortData.a_user_grade,"s_grade");
  const typeArr=getSearchItemArr(props.xColumnArr.authSortData.a_type,"s_type");
  const crudArr=getSearchItemArr(props.xColumnArr.authSortData.a_crud,"s_crud");

  let idDataArr=[];
  if(searchData.s_type=="table"){
    idDataArr=props.xColumnArr.authSortData.table_ids;
  }else if(searchData.s_type=="hmenu"){
    idDataArr=props.xColumnArr.authSortData.hmenu_ids;
  }else if(searchData.s_type=="smenu"){
    idDataArr=props.xColumnArr.authSortData.smenu_ids;
  }
  const idArr=getSearchItemArr(idDataArr,"s_id");

  return (
    <div className="con-p-gray">
      <div>
        <span className="font-bold mr-2 whitespace-nowrap" >등급</span> 
        {gradeArr}
      </div>
      <div>
        <span className="font-bold mr-2 whitespace-nowrap" >타입</span> 
        {typeArr}
      </div>
      <div>
        <span className="font-bold mr-2 whitespace-nowrap" >구분</span> 
        {idArr}
      </div>
      <div>
        <span className="font-bold mr-2 whitespace-nowrap" >권한</span> 
        {crudArr}
      </div>
    </div>
  );
}
SearchArea.defaultProps = {
  pageData:{},
  xColumnArr:{},
  list:()=>{},
}
export default SearchArea;