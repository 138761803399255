
function ViewPopupContent(props) {
  //props.rowData.a_user_name;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>작성자</th>
              <td>{props.rowData.a_writer}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                {
                  props.rowData.a_content && props.rowData.a_content.split('\n').map( (line,idx) => {
                    return (<span key={idx}>{line}<br/></span>)
                  })
                }
              </td>
            </tr>
            <tr>
              <th>작성일</th>
              <td>{props.rowData.a_create_date}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewPopupContent;
