import DateFunc from 'lib/lyg/date_func';

const getChatRows=(inOptObj)=>{
  if(inOptObj==undefined){inOptObj={};}
  let optObj={
    "msgInfoArr":[],
    "user_seq":"",
  };
  for(let key in inOptObj){
    optObj[key]=inOptObj[key];
  }
  let msgInfoArr=optObj["msgInfoArr"];
  let user_seq=optObj["user_seq"];
  let pre_create_date_str="";

  return msgInfoArr.map((item,idx)=>{
    let chat_row_class_name="chat_content_row";
    if(item.a_writer_seq==user_seq){
      chat_row_class_name+=" my_msg_row";
    }
    let top_date_line="";
    if(item.a_create_date.substring(0,10)!=pre_create_date_str){
      pre_create_date_str=item.a_create_date.substring(0,10);
      top_date_line=(
        <div className="chat_content_row_date">{pre_create_date_str}</div>
      );
    }

    let a_create_date=DateFunc.getTimeFormatOfPretty(item.a_create_date);
    let unread_span="";
    if(item.a_unread_cnt!="0"){
      unread_span=(
        <span className="chat_content_row_unread">{item.a_unread_cnt}</span>
      );
    }
    let chat_content_row=(
      <div className="chat_content_row_msg_wrap">
        <span className="chat_content_row_msg">
          {
          item.a_content.split('\n').map( (line,idx) => {
            return (<p  key={idx}>{line}</p>)
          })
          }
        </span>
        <span className="chat_content_row_time">{a_create_date}</span>
        {unread_span}
      </div>
    );
    if(item.a_writer_seq==user_seq){
      chat_content_row=(
        <div className="chat_content_row_msg_wrap">
          {unread_span}
          <span className="chat_content_row_time">{a_create_date}</span>
          <span className="chat_content_row_msg">
            {
            item.a_content.split('\n').map( (line,idx) => {
              return (<p  key={idx}>{line}</p>)
            })
            }
          </span>
        </div>
      );
    }

    return (
      <div className={chat_row_class_name} key={idx}>
        {top_date_line}
        <div className="chat_content_row_user" >
          <span className="chat_content_row_thum">👤</span>
          <span className="chat_content_row_name">{item.a_writer}</span>
        </div>
        {chat_content_row}
      </div>
    )
  });
};
export default getChatRows;