import { createSlice } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    msgInfoArr:[],
    memNowArr:[],

    unreadToT:"0",
    unreadCheckTime:"",
  },
  reducers: {
    addMsgInfoArr: (state,action) => {
      state.msgInfoArr=[
        ...state.msgInfoArr,
        ...action.payload
      ];
    },
    removeMsgInfoArr: (state) => {
      state.msgInfoArr=[];
    },
    setMemNowArr: (state,action) => {
      state.memNowArr=action.payload;
    },
    removeMemNowArr: (state) => {
      state.memNowArr=[];
    },

    setUnreadTot:(state,action) => {
      if(action.payload.tot!=undefined){
        state.unreadToT=action.payload.tot;
      }
      if(action.payload.time!=undefined){
        state.unreadCheckTime=action.payload.time;
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  addMsgInfoArr,removeMsgInfoArr,
  setMemNowArr,removeMemNowArr,
  setUnreadTot
} = chatSlice.actions

export default chatSlice.reducer