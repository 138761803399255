//import AuthFunc from 'lib/lyg/auth';
//if(AuthFunc.checkMenuAuth({"user":user,"sort":"hmenu","menuKey":menuKey})){}
//if(AuthFunc.checkTableAuth({"user":user,"tableKey":"corp","authKey":"write","data_arr":[rowData],"userKey":"a_create_user"})){}
class AuthFunc
{
  //if(AuthFunc.checkMenuAuth({"user":user,"sort":"hmenu","menuKey":menuKey})){}
  static checkMenuAuth(inOptObj){
    if(!inOptObj==undefined){inOptObj={};}
    let optObj={
      "user":null,
      "sort":"smenu",//hmenu
      "menuKey":"",
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }
    if(optObj["user"]==undefined||optObj["user"]==null){
      return false;
    }
    if(optObj["user"]["user_grade"]=="master"){
      return true;
    }
    let authMenuArr=[];
    if(optObj["sort"]=="smenu"){
      authMenuArr=optObj["user"]["smenuArr"];
    }else{
      authMenuArr=optObj["user"]["hmenuArr"];
    }

    let is_auth=false;
    for(let i=0;i<authMenuArr.length;i++){
      if(authMenuArr[i]==optObj["menuKey"]){
        is_auth=true;
      }
    }
    return is_auth;
  }
  //if(AuthFunc.checkTableAuth({"user":user,"tableKey":"corp","authKey":"write","data_arr":[rowData],"userKey":"a_create_user"})){}
  static checkTableAuth(inOptObj){
    if(!inOptObj==undefined){inOptObj={};}
    let optObj={
      "user":null,
      "tableKey":"",
      "authKey":"",
      "data_arr":[],
      "userKey":"",//a_create_user
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }
    if(optObj["user"]==undefined||optObj["user"]==null){
      return false;
    }
    if(optObj["user"]["user_grade"]=="master"){
      return true;
    }
    if(optObj["tableKey"]==""||optObj["authKey"]==""){
      return false;
    }
    let is_all_writer=true;
    for(let i=0;i<optObj["data_arr"].length;i++){
      let userKey=optObj["userKey"];
      if(optObj["data_arr"][i][userKey]!=optObj["user"]["user_seq"]){
        is_all_writer=false;
      }
    }
    if(optObj["data_arr"].length>0&&is_all_writer){
      return true;
    }

    let tableKey=inOptObj["tableKey"];
    if(optObj["user"]["tableKeyArr"][tableKey]==undefined){
      return false;
    }
    let is_auth=false;
    for(let i=0;i<optObj["user"]["tableKeyArr"][tableKey].length;i++){
      if(optObj["user"]["tableKeyArr"][tableKey][i]==optObj["authKey"]){
        is_auth=true;
      }
    }
    return is_auth;
  }
  static checkTableAuthXcolumn(inOptObj){
    if(!inOptObj==undefined){inOptObj={};}
    let optObj={
      "user":null,
      "authKey":"",
      "data_arr":[],
      "xColumnArr":null,//a_create_user
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }
    if(optObj["xColumnArr"]==null){
      return false;
    }

    return this.checkTableAuth({
      "user":optObj["user"],
      "tableKey":optObj["xColumnArr"].table,
      "authKey":optObj["authKey"],
      "data_arr":optObj["data_arr"],
      "userKey":optObj["xColumnArr"].create_user_col
    });
  }
}
export default AuthFunc;