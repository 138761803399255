import strFunc from 'lib/lyg/string';

function ViewPopupContent(props) {
  //props.rowData.a_user_name;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>프로젝트</th>
              <td>{props.rowData.a_project_name}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                {
                  props.rowData.a_content.split('\n').map( (line,idx) => {
                    return (<span  key={idx}>{line}<br/></span>)
                  })
                }
              </td>
            </tr>
            <tr>
              <th>시작</th>
              <td>{props.rowData.a_start_date}</td>
            </tr>
            <tr>
              <th>종료</th>
              <td>{props.rowData.a_end_date}</td>
            </tr>
            <tr>
              <th>생성일</th>
              <td>{props.rowData.a_create_date}</td>
            </tr>
            <tr>
              <th>수정일</th>
              <td>{props.rowData.a_update_date}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
