import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paging from 'components/common/list/paging/paging';
import ListAggrid from 'components/common/list/aggrid/list';
import getXcolumnJson from './xcolumn/list.js';

function FindUserList(props) {
  const xColumnArr=getXcolumnJson();
  const user = useSelector((state) => state.user);
  const listAggridRef= useRef();
  const [pageData, setPageData] = useState({
    listOpt:{
      "s_except_user_seq":props.s_except_user_seq,
      ...xColumnArr.list_opt
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  
  useEffect(() => {
    //list();
  }, []);

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/user/user/list',pageData.listOpt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const setSelectUser=()=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    if(!confirm("선택 회원을 초대 하시겠습니까?")){
      return false;
    }
    props.callback(selected_row);
    props.closePopup();
  };

  const cellClickedListener = useCallback( params => {
    var key=params.colDef.field;
    if(key=="a_user_name"){
      setSelectUser();
    }
  }, []);

  const cellRenderer= useCallback( params => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="a_user_name"){
      render_str=<a href="#">{render_str}</a>;
    }
    return render_str;
  }, []);

  return (
    <div>
      <div className="btn-box-left">
        <button className="btn btn-gray" onClick={()=>{setSelectUser();}} >선택</button>
        <button className="btn btn-blue" onClick={()=>{list({"now_page":1});}} >검색</button>
        <button className="btn btn-gray" onClick={()=>{props.closePopup();}} >닫기</button>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:false,
          is_add_checkbox:false,
          floatingFilter:true,
          onGridReady:()=>{
            list();
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer
        }}
      ></ListAggrid>
      <div>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
      </div>
    </div>
  );
}

FindUserList.defaultProps = {
  s_except_user_seq:[],
  closePopup:()=>{},
  callback:()=>{},
}

export default FindUserList;
