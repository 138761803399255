import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user_id:"",
    user_seq:"",
    user_name:"",
    user_grade:"",
    hmenuArr:[],
    smenuArr:[],
    tableKeyArr:{
      //"corp":["read","write","delete","update","list","display"],
    },
  },
  reducers: {
    removeUser: (state) => {
      state.user_id ="";
      state.user_seq ="";
      state.user_name ="";
    },
    setUser: (state, action) => {
      for(let key in state){
        if(action.payload[key]!=undefined){
          state[key] =action.payload[key];
        }
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { removeUser,setUser } = userSlice.actions

export default userSlice.reducer