import HomePage from 'pages/home/main/index';
import LoginPage from 'pages/home/login/login';
import JoinPage from 'pages/home/join/index';

import UserListPage from 'pages/home/basic/user/list/index';
import CorpList from 'pages/home/basic/corp/list/index';
import AuthgList from 'pages/home/basic/authg/list/index';
import UserAuthList from 'pages/home/basic/user_auth/list/index';
import SortList from 'pages/home/basic/sort/list/index';
import FileList from 'pages/home/basic/file/list/index';
import ListOptList from 'pages/home/basic/list_opt/list/index';
import ReplyList from 'pages/home/basic/reply/list/index';
import MyPage from 'pages/home/basic/mypage/index';
import LogLoginList from 'pages/home/basic/log_login/list/index';


import ProjectMain from 'pages/home/project/main/index';
import ProjectList from 'pages/home/project/project/list/index';
import ConferenceList from 'pages/home/project/conference/list/index';
import PlanList from 'pages/home/project/plan/list/index';
import WorkList from 'pages/home/project/work/list/index';
import ReportList from 'pages/home/project/report/list/index';

import CommunityMain from 'pages/home/community/main/index';
import BoardList from 'pages/home/community/notice/list/index';

const HomePages=[
  {
    path: "/",
    element: <HomePage></HomePage>,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/join",
    element: <JoinPage></JoinPage>,
  },
  {
    path: "/home/corp/list",
    element: <CorpList></CorpList>,
  },
  {
    path: "/home/user/list",
    element: <UserListPage></UserListPage>,
  },
  {
    path: "/home/authg/list",
    element: <AuthgList></AuthgList>,
  },
  {
    path: "/home/user_auth/list",
    element: <UserAuthList></UserAuthList>,
  },
  {
    path: "/home/sort/list",
    element: <SortList></SortList>,
  },
  {
    path: "/home/file/list",
    element: <FileList></FileList>,
  },
  {
    path: "/home/list_opt/list",
    element: <ListOptList></ListOptList>,
  },
  {
    path: "/home/reply/list",
    element: <ReplyList></ReplyList>,
  },
  {
    path: "/home/mypage",
    element: <MyPage></MyPage>,
  },
  {
    path: "/home/login_log/list",
    element: <LogLoginList></LogLoginList>,
  },
  
  
  {
    path: "/home/project",
    element: <ProjectMain></ProjectMain>,
  },
  {
    path: "/home/project/list",
    element: <ProjectList></ProjectList>,
  },
  {
    path: "/home/conference/list",
    element: <ConferenceList></ConferenceList>,
  },
  {
    path: "/home/plan/list",
    element: <PlanList></PlanList>,
  },
  {
    path: "/home/work/list",
    element: <WorkList></WorkList>,
  },
  {
    path: "/home/report/list",
    element: <ReportList></ReportList>,
  },

  {
    path: "/home/community",
    element: <CommunityMain></CommunityMain>,
  },
  {
    path: "/home/board/list",
    element: <BoardList></BoardList>,
  },
];

export default HomePages;