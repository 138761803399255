import XcolumnFunc from "components/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();
import DateFunc from 'lib/lyg/date_func';

const getXcolumnJson=()=>{
  let initData={
    table:"log_login",
    list_id:"log_login_list",
    create_user_col:"a_user_seq",
    x_column_list_arr:[
      {"key":"a_user_name","name":"이름","width":"120","is_show":"1"},
      {"key":"a_ip","name":"아이피","width":"130","is_show":"1"},
      {"key":"a_server_ip","name":"서버아이피","width":"130","is_show":"1"},
      {"key":"a_agent","name":"에이젠트","width":"160","is_show":"1"},
      {"key":"a_login_url","name":"로그인주소","width":"110","is_show":"1"},
      {"key":"a_login_sort","name":"종류","width":"110","is_show":"1"},
      {"key":"a_create_date","name":"생성일","width":"160","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'a_create_date',
      's_start_date':DateFunc.get_date_format(new Date(),"Y-m-d"),
      's_end_date':'',
      's_user_seq':'',
      'sc':{},
    },
    order_id_col_arr:[
      {"value":"a_create_date","text":"작성일"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;