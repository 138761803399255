import { useEffect } from 'react';
import {hmenu,smenu} from './data/menu_arr';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AuthFunc from 'lib/lyg/auth';

function TopMenu(props){
  const user = useSelector((state) => state.user);
  useEffect(() => {
    
  }, []);

  let now_hkey="";
  for (var skey in smenu) {
    if(window.location.pathname===smenu[skey]["url"]){
      now_hkey=smenu[skey]["hmenu"];
      document.title = smenu[skey]["name"];
    }
  }
  
  const menuArr = Object.entries(hmenu).map(([key,menu_data])=>{
    let hmenu_class_name="header-nav-item";
    if(key===now_hkey){
      hmenu_class_name="header-nav-item active";
    }
    if(!AuthFunc.checkMenuAuth({"user":user,"sort":"hmenu","menuKey":key})){
      hmenu_class_name+=" hidden";
    }

    return (
      <Link className={hmenu_class_name} to={menu_data["url"]} key={key}>{menu_data["name"]}</Link>
    );
  });

  return (
    <div className="header-item">
      <div className="header-nav-wrap">
        {menuArr}
      </div>
    </div>
  );
}

export default TopMenu;
