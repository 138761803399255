import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from 'store/user/user';
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import DateFunc from 'lib/lyg/date_func';

const WriteInputFrame = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const fileInput = useRef();
  const writeForm = useRef();

  const [rowData,setRowData]=useState({
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_par_id":props.init_data.a_par_id,
    "a_par_seq":props.init_data.a_par_seq,
    "a_sort1":props.init_data.a_sort1,
    "a_sort2":props.init_data.a_sort2,
    "a_sort3":props.init_data.a_sort3,
    "a_writer":user.user_name,
    "a_create_user":user.user_seq,
  });

  useEffect(()=>{
    if(props.isUpdate){
      let changeRowData={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const goWrite=useCallback( (inOptObj) => {
    if(inOptObj===undefined){inOptObj={};}
    let optObj={
      //"a_par_seq":""
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }
    let tmpRowData={
      ...rowData,
      ...optObj
    };
    setRowData(tmpRowData);
    if(tmpRowData.a_par_seq==""||tmpRowData.a_par_id==""){
      alert("파일 부모 정보가 올바르지 않습니다.");
      return false;
    }

    if(fileInput.current.value==""){
      alert("파일 선택이 필요합니다.");
      return false;
    }

    var form = writeForm.current;
    var form_json_data= new FormData(form);
    form_json_data.append("data_arr",JSON.stringify([tmpRowData]));
    form_json_data.append("authorization",localStorage.lyg_mproject_token_id);
    
    window.axios.post( process.env.REACT_APP_FILE_SERVER_URL+'/api/comp/file/file/receive',form_json_data)
    .then((response) => {
      if(response.data["result"]==="true"){
        props.callback(response.data["data"]);
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  const getFileInput=()=>{
    return fileInput;
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    goWrite,getFileInput
  }))

  return (
    <div>
      <form id="write_form" method="post" ref={writeForm} >
      <p className="con-p-gray">첨부파일</p>
      <input type="file" id="files" className="row-input" name="input_file[]" ref={fileInput} />
        <div className="write-table-div mt-1">
          <table>
            <colgroup>
              <col width="130px"></col>
              <col width="*"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>설명</th>
                <td>
                  <input type="text" className="row-input" name="a_sort3" value={rowData.a_sort3||""} onChange={handleInputChange} placeholder="설명" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
});

WriteInputFrame.defaultProps = {
  "isUpdate":false,
  "init_data":{
    "a_par_id":"",
    "a_par_seq":"",
    "a_sort1":"",
    "a_sort2":"",
    "a_sort3":"",
  },
  "rowData":{},
  "callback":()=>{},
}

export default WriteInputFrame;
