//import ListComponent from 'components/common/list/list/list_component';
import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';

const ListComponent=forwardRef((props, ref) => {
  const listTableRef=useRef();
  const [infoArr,setInfoArr]= useState(props.infoArr);
  const xColumnArr=props.xColumnArr;
  const gridOpt={
    is_add_idx_num:true,
    is_add_checkbox:true,
    width:"100%",
    td_align:"left",//center,right
    table_align:"left",//center,right
    onGridReady:()=>{},
    rowOnClick:(rowData,idx,e)=>{

    },
    ...props.gridOpt
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    getSelectedRows,getRows,setInfoArr
  }));

  useEffect(() => {
    gridOpt.onGridReady();
  },[]);
  
  const getSelectedRows=()=>{
    let selectedRows=[];
    for(let i=0;i<infoArr.length;i++){
      if(infoArr[i].is_active){
        selectedRows.push({...infoArr[i]});
      }
    }
    return selectedRows;
  };
  const getRows=()=>{
    return [...infoArr];
  };

  const rowOnClick=(rowData,idx,e)=>{
    let tmpInfoArr=[...infoArr];
    for(let i=0;i<tmpInfoArr.length;i++){
      if(e.target.className!="chk_row"){
        tmpInfoArr[i]["is_active"]=false;
      }
      if(i==idx){
        if(e.target.className=="chk_row"){
          tmpInfoArr[i]["is_active"]=!tmpInfoArr[i]["is_active"];
        }else{
          tmpInfoArr[i]["is_active"]=true;
        }
      }
    }
    setInfoArr(tmpInfoArr);
    gridOpt.rowOnClick(rowData,idx,e);
  };

  const checkAll=(e)=>{
    let is_check=false;
    if(e.target.checked){
      is_check=true;
    }
    let tmpInfoArr=[...infoArr];
    for(let i=0;i<tmpInfoArr.length;i++){
      tmpInfoArr[i]["is_active"]=is_check;
    }
    setInfoArr(tmpInfoArr);
  };

  const getListColumnDefs=()=>{
    let tmpColDefs=[];
    if(gridOpt.is_add_checkbox){
      tmpColDefs.push({"key":"checkbox",
        "name":<input type="checkbox" onChange={checkAll} />,
        "width":"30","is_show":"1"});
    }
    if(gridOpt.is_add_idx_num){
      tmpColDefs.push({"key":"idx","name":"No.","width":"60","is_show":"1"});
    }
    tmpColDefs=[
      ...tmpColDefs,
      ...xColumnArr.x_column_list_arr
    ];
    return tmpColDefs;
  };

  let colDefs=getListColumnDefs();
  const headerCols = colDefs.map((col_data,idx)=>{
    let key=col_data["key"];
    let tmp_col_width=parseInt(col_data["width"]);
    return (
      <th key={key}>
        <div className="row-col-div" style={{width:tmp_col_width}}>
          {col_data["name"]}
        </div>
      </th>
    );
  });
  const tBodyRows = infoArr.map((rowData,idx)=>{
    let trClassName="data_row_tr";
    if(rowData.is_active){
      trClassName="data_row_tr active";
    }
    let tdCols=colDefs.map((col_data,idx)=>{
      let key=col_data["key"];
      let tmp_col_width=parseInt(col_data["width"]);
      let row_val="";
      if(rowData[key]!=undefined){
        row_val=rowData[key];
      }
      let td_className="";
      if(key=="checkbox"){
        let isChecked=false;
        if(rowData.is_active){
          isChecked=true;
        }
        row_val=<input type="checkbox" className="chk_row" checked={isChecked} onChange={()=>{}} />;
        td_className="text-center";
      }else{
        if(gridOpt.td_align=="center"){
          td_className="text-center";
        }else if(gridOpt.td_align=="right"){
          td_className="text-right";
        }
      }
      row_val=props.customCellRenderer({
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      });
      return (
        <td key={key} className={td_className} >
          <div className="row-col-div" style={{width:tmp_col_width}}>
            {row_val}
          </div>
        </td>
      );
    });
    return (
      <tr key={idx} className={trClassName} onClick={(e)=>{rowOnClick(rowData,idx,e);}}>
        {tdCols}
      </tr>
    );
  });

  const tableStyle={
    width:gridOpt.width
  };
  if(gridOpt.table_align=="center"){
    tableStyle["margin"]="0 auto";
  }

  return (
    <div className="list-table-div" ref={listTableRef} >
      <table style={tableStyle}>
        <thead>
          <tr>
            {headerCols}
          </tr>
        </thead>
        <tbody>
          {tBodyRows}
        </tbody>
      </table>
    </div>
  );
});

ListComponent.defaultProps = {
  "infoArr":[],//[{"a_ymd":"20221212","a_seq":"1","a_filename":"파일명","is_active":false}]
  "xColumnArr":{
    "x_column_list_arr":[]//{"key":"row_view_thum","name":"썸네일","width":"100"},
  },
  "gridOpt":{},
  "customCellRenderer":(params)=>{
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val
    }
    */
    return params.value;
  },
}

export default ListComponent;
