import TopMenu from 'components/common/test/menu/top_menu';
import SubMenu from '../menu.js';
import React, { useState, useEffect,useRef } from 'react';
import './chat.css';
import { useSelector } from 'react-redux';

function Chat1() {
  const user = useSelector((state) => state.user);
  const [chatName, setChatName] = useState(user.user_name);
  const [chatMsg, setChatMsg] = useState("");
  const [chat,setChat] =useState([]);
  const sendMsgInput = useRef();

  useEffect(() => {
    window.web_socket.on('chat message', function(msg) {
      setChat([...chat,msg]);
    });
  });
  useEffect(() => {
    sendMsgInput.current.focus();
  },[]);
  const handleSubmit=(event)=>{
    event.preventDefault();
    if (chatMsg) {
      window.web_socket.emit('chat message', {name:chatName,msg:chatMsg});
      setChatMsg("");
      sendMsgInput.current.focus();
    }
  }

  const renderChat =()=>{
    setTimeout(function(){
      window.scrollTo(0, document.body.scrollHeight);
    },100);
    return chat.map((row_data,index)=>(
      <li key={index}>
          {index}
          <span className="text-gray-700 px-5">{row_data.name}</span>
          <span>{row_data.msg}</span>
      </li>
    ))
  }

  return (
    <div>
      <TopMenu></TopMenu>
      <SubMenu></SubMenu>
      <h2 className="con-p-gray" >채팅</h2>
      <div id="chat_wrap">
        <ul className="messages" id="messages">{renderChat()}</ul>
        <form id="form" action="" onSubmit={handleSubmit} >
          <input className="sendName" autoComplete="off" value={chatName} onChange={(e)=>{setChatName(e.target.value)}} placeholder="이름" />
          <input className="sendMsg" autoComplete="off" value={chatMsg} onChange={(e)=>{setChatMsg(e.target.value)}} ref={sendMsgInput} />
          <button>Send</button>
        </form>
      </div>
    </div>
  );
}

export default Chat1;
