import TopMenu from 'components/common/test/menu/top_menu';
import SubMenu from '../menu.js';
import { useEffect,useState,useRef } from 'react';
import ListComponent from "components/common/list/list/list_component";

function FileList() {
  const listComponentRef = useRef();
  const [infoArr,setInfoArr]= useState([
    {"a_ymd":"20221212","a_seq":"1","a_filename":"파일명","a_sort3":"구분3","is_image":"1","thum_img_url":process.env.REACT_APP_FILE_SERVER_URL+"/uploads/tmp/stopwatch.png","is_active":false},
    {"a_ymd":"20221212","a_seq":"2","a_filename":"파일명2","a_sort3":"구분z","is_image":"1","thum_img_url":process.env.REACT_APP_FILE_SERVER_URL+"/uploads/tmp/stopwatch.png","is_active":false},
    {"a_ymd":"20221212","a_seq":"3","a_filename":"파일명3","a_sort3":"구분c","is_image":"1","thum_img_url":process.env.REACT_APP_FILE_SERVER_URL+"/uploads/tmp/stopwatch.png","is_active":false},
    {"a_ymd":"20221212","a_seq":"4","a_filename":"파일명4","a_sort3":"구분v","is_image":"1","thum_img_url":process.env.REACT_APP_FILE_SERVER_URL+"/uploads/tmp/stopwatch.png","is_active":false},
  ]);
  const xColumnArr={
    x_column_list_arr:{
      "row_view_thum":{"name":"썸네일","width":"120"},
      "a_filename":{"name":"파일명","width":"120"},
      "a_sort3":{"name":"구분","width":"120"},
    },
  };
  const customCellRenderer=(params)=>{
    let row_val="";
    if(params.rowData[params.key]!=undefined){
      row_val=params.rowData[params.key];
    }
    if(params.key=="row_view_thum"){
      if(params.rowData["is_image"]=="1"){
        row_val=
          <div className="text-center">
            <a href="#" onClick={()=>{window.open(params.rowData.thum_img_url);}} >
              <img src={params.rowData.thum_img_url} style={{height:25}} />
            </a>
          </div>;
      }
    }
    return row_val;
  };

  useEffect(() => {
    
  });

  return (
    <div>
      <TopMenu></TopMenu>
      <SubMenu></SubMenu>
      <h2 className="con-title" >파일리스트</h2>
      <div className="btn-box-center">
        <ListComponent ref={listComponentRef} infoArr={infoArr} xColumnArr={xColumnArr} customCellRenderer={customCellRenderer} ></ListComponent>
      </div>
      <div className="btn-box-center">
        <button className="btn btn-blue" onClick={()=>{
          console.log(listComponentRef.current.getSelectedRows());
        }} >선택얻기</button>
      </div>
    </div>
  );
}

export default FileList;
