import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from 'store/user/user';

function WritePopupContent(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  return (
    <div>
      <div className="con-p-gray">내용 영역</div>
      <button className="btn btn-dark2">버튼</button>
      <div className="con-p-gray">{props.userName}</div>
      <button className="btn btn-dark2" onClick={()=>{props.closePopup();}}>X</button>
      <button className="btn btn-dark2"
        onClick={() => dispatch(setUser({
          user_id:'lyg0105',
          user_seq:33,
          user_name:'용균'
        }))} >
        세팅
      </button>
      <button className="btn btn-dark2"
        onClick={() => dispatch(removeUser())} >
        지우기
      </button>

      <p className="con-p-gray">
        {user.user_id}
      </p>
      <p className="con-p-gray">
        {user.user_seq}
      </p>
      <p className="con-p-gray">
        {user.user_name}
      </p>
    </div>
  );
}

export default WritePopupContent;
