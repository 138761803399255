import HoemHeader from 'components/home/header/header';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paging from 'components/common/list/paging/paging';
import ListAggrid from 'components/common/list/aggrid/list';
import getXcolumnJson from '../xcolumn/list';
import LayerPopup from 'components/common/popup/layer/layer_popup';
import WriteOptPopupContent from 'components/common/xcolumn/popup/write_opt/index';

const ListOptList=(props,ref)=> {
  const user = useSelector((state) => state.user);
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });
  useEffect(() => {
    //list();
  }, []);

  const list = (inOptObj)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/list_opt/list_opt/list',pageData.listOpt)
    .then((response) => {
      //console.log(response.data);
      if(response.data["result"]==="true"){
        let tmp_info_arr=response.data["data"]["info_arr"];
        let start_index=parseInt(response.data["data"]["start_index"]);
        for(let i=0;i<tmp_info_arr.length;i++){
          tmp_info_arr[i].idx_num=start_index;
          start_index--;
        }
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( e => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    let tmp_delete_rows=[];
    for(let i=0; i<selected_row.length; i++){
      tmp_delete_rows.push({
        "a_user_seq":selected_row[i]["a_user_seq"],
        "a_list_id":selected_row[i]["a_list_id"],
        "a_list_type":selected_row[i]["a_list_type"],
      });
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    window.axios.post( process.env.REACT_APP_API_URL+'/api/comp/list_opt/list_opt/delete',{
      data_arr:tmp_delete_rows,
    },).then((response) => {
      if(response.data["result"]==="true"){
        localStorage.xcolumn_create_date="";
        list();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const openOnePopup=(isUpdate,sort)=>{
    if(isUpdate==undefined){isUpdate=false;}
    if(sort==undefined){sort="listOpt";}
    let tmp_rowData={};
    let pop_title="등록";
    if(sort=="listOpt"){
      pop_title="리스트설정";
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "title":pop_title,
      "sort":sort
    });
  };

  return (
    <div>
      <HoemHeader></HoemHeader>
      <div className="main-content-wrap">
        <div className="main-content-div">
          <h1 className="con-p-gray">
            리스트설정
            ({countInfo["tot"]} 건) 
          </h1>
          <div className="btn-box-left">
            <button className="btn btn-round-orange" onClick={goDelete}>삭제</button>
            <button className="btn btn-dark" onClick={()=>{openOnePopup(false,"listOpt");}}>리스트설정</button>
          </div>
          <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{...xColumnArr}}
            list={list}
            gridOpt={{
              fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
              onGridReady:()=>{
                list();
              }
            }}
          ></ListAggrid>
          <div>
            <Paging now_page={pageData.listOpt.now_page}
              num_per_page={pageData.listOpt.num_per_page}
              total_rec={countInfo.tot} 
              onChangePage={(now_page)=>{list({now_page:now_page});}}></Paging>
          </div>
        </div>
      </div>
      {popupData.isOpen && 
        <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
          width={popupData.width} height={popupData.height} >
          {popupData.sort==="listOpt"&&
            <WriteOptPopupContent xColumnArr={{...xColumnArr}} callback={()=>{list();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></WriteOptPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
};

export default ListOptList;
